<script>
import FormInput from "../../mixins/FormInput";

export default {
  name: "HasManyNested",
  extends: FormInput,
  props: ["resourceName"],

  computed: {
    resource() {
      return this.$store.state.resources[this.resourceName];
    },
  },

  created() {
    this.$store.dispatch("initResourceOptions", this.resourceName);
  },
};
</script>

<template>
  <v-autocomplete
    v-bind="inputOptions"
    :value="value"
    :items="resource.items"
    :loading="resource.loading"
    item-value="id"
    multiple
    return-object
    chips
    deletable-chips
    @input="$emit('input', $event)"
  />
</template>
