import { saveAs } from "file-saver";

export class DownloadFile {
  constructor(data, name) {
    this.data = data;
    this.name = name;
  }

  save() {
    saveAs(this.data, this.name);
  }
}
