<script>
import resources from "../resources";
import { value } from "../utils";

export default {
  name: "ResourceActionsMenu",
  props: {
    resourceName: {
      type: String,
      required: true,
    },

    data: {
      type: Object,
    },
  },

  data: () => ({
    isShowing: false,
    currentAction: null,
    loading: false,
  }),

  computed: {
    resource() {
      return resources[this.resourceName];
    },

    actions() {
      return this.resource.actions.filter(a => (this.data ? !a.index && !value(a.hide, this.data) : a.index));
    },
  },
};
</script>

<template>
  <v-menu v-if="actions.length > 0">
    <template v-slot:activator="{ on }">
      <slot :on="on" />
    </template>

    <v-list dense>
      <v-list-item v-for="action in actions" :key="action.id" link @click="$emit('choose', action)">
        <v-list-item-title>{{ action.title }}</v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>
