import resources from "@/resources";
import { HttpException } from '@/plugins/api';
import FlashesMessages from "./FlashesMessages";

export default {
  inheritAttrs: false,
  mixins: [FlashesMessages],

  data: () => ({
    loading: false,
    loaded: false,
    data: {},
  }),

  beforeRouteEnter(to, from, next) {
    next(vm => vm.load(to.params));
  },

  beforeRouteUpdate(to, from, next) {
    if (from.path === to.path) {
      next();

      return;
    }

    this.load(to.params).then(
      () => next(),
      err => next(err)
    );
  },

  computed: {
    resourceName() {
      return this.$route.params.resource;
    },

    key() {
      return this.$route.params.key;
    },

    resource() {
      return (this.resourceName && resources[this.resourceName]) || {};
    },
  },

  methods: {
    async load(options = null) {
      if (this._cancelToken) this._cancelToken.cancel();

      this._cancelToken = this.$http.CancelToken.source();
      this.loading = true;

      try {
        const resp = await this._dataXhr(options);

        this._updateData(resp);

        this.loading = false;
        this.loaded = true;
      } catch (err) {
        if (err instanceof this.$http.Cancel) return;

        this.loading = false;

        if (err instanceof HttpException) {
          if (err.code === 404) {
            await this.$router.replace(`/${err.code}`);
          } else {
            this.flash(err.data.message || "Неизвестная ошибка", "error");
          }
        } else {
          this.flash("Неизвестная ошибка", "error");
        }
      }
    },

    _dataXhr(options) {
      return this.$api.get(this._dataUrl(options), this._cancelToken.token);
    },

    _updateData(resp) {
      this.data = resp.data;
    },

    _dataUrl(options) {
      return (options ? resources[options.resource] : this.resource).url((options || this).key);
    },
  },
};
