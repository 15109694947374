<script>
import _ from "lodash";
import resources from "../resources";
import fields from "./columns";
import HasGetter from "../mixins/HasGetter";
import ConfirmationDialog from "./ConfirmationDialog";
import { difference, value } from "@/utils";
import FlashesMessages from "../mixins/FlashesMessages";
import FlashMessage from "./FlashMessage";
import ResourceActionsMenu from "./ResourceActionsMenu";
import ResourceActionsDialog from "./ResourceActionDialog";
import ResourceListFilters from "./ResourceListFilters";
import { HttpException } from "@/plugins/api";
import { CSV } from "@/core/csv";

export default {
  name: "ResourceList",
  mixins: [HasGetter, FlashesMessages],
  components: {
    ResourceListFilters,
    ResourceActionsDialog,
    ResourceActionsMenu,
    FlashMessage,
    ...fields,
    ConfirmationDialog,
  },

  props: {
    resourceName: {
      type: String,
      required: true,
    },

    parent: null,
    relationConfig: null,

    label: String,
    initialItems: null,
    perPage: null,
    canCreate: {
      type: Boolean,
      default: true,
    },
    view: String,
  },

  data: () => ({
    loading: false,
    serverItemsLength: null,
    items: [],
    itemToDelete: null,
    defaultParams: {},
    filterDefaults: {},
    isRunningAction: false,
    currentItem: null,
    currentAction: null,
  }),

  computed: {
    resource() {
      return resources[this.resourceName] || {};
    },

    primaryKey() {
      return this.resource.primaryKey || "id";
    },

    headers() {
      const items = this.view ? this.resource.views[this.view].fields : this.resource.fields;

      const headers = items
        .filter(field => {
          return (
            field.id !== this.relationConfig?.foreignKey &&
            field.id !== this.relationConfig?.morphKeyName &&
            value(field.hide) !== true &&
            value(field.hideFromIndex) !== true &&
            fields[field.component || "string"] !== undefined
          );
        })
        .map(field => ({
          field,
          value: field.id,
          text: field.label,
          sortable: field.sortable !== false && field.component !== "computed",
        }));

      headers.push({
        text: "Действия",
        sortable: false,
      });

      return headers;
    },

    csvFields() {
      return (this.view ? this.resource.views[this.view].fields : this.resource.fields).filter(field => {
        return (
          value(field.hide) !== true &&
          value(field.hideFromCSV) !== true &&
          fields[field.component || "string"] !== undefined
        );
      });
    },

    filterParams() {
      const res = {};

      ((this.view ? this.resource.views[this.view] : this.resource).filters || []).forEach(
        f => (res[f.id] = this._getQuery(f.id, f.multiple))
      );

      return res;
    },

    scopeKey() {
      return this.relationConfig?.path(this.parent);
    },

    indexParams() {
      return {
        page: parseInt(this._getQuery("page")),
        perPage: parseInt(this._getQuery("perPage")),
        sortBy: this._getQuery("sortBy"),
        sortDesc: !!this._getQuery("sortDesc"),
        search: this._getQuery("search"),
        scope: this.scopeKey,
        ...this.filterParams,
      };
    },

    footerProps() {
      return {
        itemsPerPageOptions: [5, 15, 50, 100],
      };
    },

    linkToCreate() {
      if (this.relationConfig) {
        return this.relationConfig.path(this.parent) + "/create";
      }

      return this.resource.path(this.data) + "/create";
    },

    title() {
      return (this.view && this.resource.views[this.view].title) || this.resource.title;
    },
  },

  watch: {
    indexParams: {
      deep: true,
      handler: function(a, b) {
        if (!_.isEmpty(difference(a, b))) {
          this.load();
        }
      },
    },

    resource: {
      immediate: true,
      handler: function(v) {
        this.filterDefaults = v.filterDefaults(this.view);
        this.defaultParams = {
          page: 1,
          perPage: this.perPage || v.perPage,
          sortBy: v.sortBy,
          sortDesc: v.sortDesc,
          search: null,
          ...this.filterDefaults,
        };

        if (this.initialItems) {
          this.items = this.initialItems;
          this.serverItemsLength = this.initialItems.length;
        } else {
          this.load();
        }
      },
    },
  },

  methods: {
    _getData(params, cancelToken) {
      const data = {
        page: params.page,
        per_page: params.perPage,
        sort_by: params.sortBy,
        sort_desc: params.sortDesc ? 1 : undefined,
        search: params.search,
        ...this.filterParams,
      };

      this.relationConfig?.applyFilters(this.parent, data);

      return this.$api.index(this.view
        ? this.resource.views[this.view].url
        : this.relationConfig?.url(this.parent) || this.resource.url(), data, cancelToken);
    },

    async load() {
      if (!this._allows("viewAny")
        || this.resource.static
        || this.relationConfig && !this.relationConfig.resolveForeignKey(this.parent)
      ) return;

      if (this._cancelToken) this._cancelToken.cancel();

      this._cancelToken = this.$http.CancelToken.source();

      this.loading = true;

      try {
        const resp = await this._getData(this.indexParams, this._cancelToken.token);

        this.items = Object.freeze(resp.data);
        this.serverItemsLength = resp.meta.total;
        this._cancelToken = null;
        this.loading = false;
      } catch (err) {
        this._handleError(err);
      }
    },

    _handleError(err) {
      if (err instanceof this.$http.Cancel) return;

      this.loading = false;

      if (err instanceof HttpException) {
        this.flash(err.data.message, "error");
      } else {
        this.flash("Неизвестная ошибка", "error");
      }

      throw err;
    },

    updateParams(params) {
      const query = { ...this.$route.query };
      const regex = new RegExp(`^${this.resourceName}_`);
      const defaults = this.defaultParams;

      _.keys(query).forEach(key => {
        if (regex.test(key)) delete query[key];
      });

      _.keys(this.indexParams).forEach(key => {
        if (key === "scope") return;

        let value = params.hasOwnProperty(key) ? params[key] : this.indexParams[key];

        if (!_.isEqual(value, defaults[key])) {
          if (typeof value === "boolean") value = value ? 1 : 0;

          query[`${this.resourceName}_${_.snakeCase(key)}`] = value;
        }
      });

      if (!_.isEqual(query, this.$route.query)) {
        this.$router.push({ query });
      }
    },

    trash(item) {
      this.itemToDelete = item;
    },

    runAction(item, action) {
      this.currentAction = action;
      this.currentItem = item;
      this.isRunningAction = true;
    },

    _getQuery(key, array) {
      let value = this.$route.query[`${this.resourceName}_${_.snakeCase(key)}`];

      value = value === undefined ? this.defaultParams[key] : value;

      return value && array && !_.isArray(value) ? [value] : value;
    },

    _updateFromTableOptions(opts) {
      this.updateParams({
        perPage: opts.itemsPerPage,
        page: opts.page,
        sortBy: opts.sortBy[0] || null,
        sortDesc: opts.sortDesc[0] || false,
      });
    },

    _updateSearch: _.debounce(function(value) {
      this.updateParams({ search: value });
    }, 1000),

    _updateFilter(field, value) {
      this.updateParams({ [field.id]: value });
    },

    async _confirmTrash() {
      try {
        this.loading = true;

        await this.$api.delete(
          this.relationConfig?.url(this.parent, this.itemToDelete) || this.resource.url(this.itemToDelete)
        );

        if (this.resource.static) {
          this.items.splice(this.items.indexOf(this.itemToDelete), 1);
        } else {
          this.$nextTick(this.load);
        }
      } catch (err) {
        this.flash("Не удалось удалить запись", "error");
      } finally {
        this.loading = false;
      }
    },

    async _downloadCSV() {
      if (this.loading) return;

      const params = { ...this.indexParams };

      params.per_page = 100;
      params.page = 1;

      const csv = new CSV(this.csvFields);

      if (this.resource.static) {
        csv.push(this.initialItems);
      } else {
        try {
          this.loading = true;

          let resp;

          do {
            resp = await this._getData(params);

            csv.push(resp.data);

            params.page++;
          } while (resp.meta.current_page < resp.meta.last_page);
        } catch (err) {
          this._handleError(err);
        } finally {
          this.loading = false;
        }
      }

      csv.save(`${this.resourceName}${this.view ? "-" + this.view : ""}.csv`);
    },

    _rowClass(item) {
      return value(this.resource.rowClass, item);
    },

    _linkToEdit(item) {
      if (this.relationConfig) {
        return this.relationConfig.path(this.parent, item) + '/edit';
      }

      return this.resource.path(item) + '/edit';
    },

    _linkToShow(item) {
      if (this.relationConfig) {
        return this.relationConfig.path(this.parent, item);
      }

      return this.resource.path(item);
    },

    _allows(action, item) {
      return this.relationConfig
        ? this.relationConfig.allows(action, this.parent, item)
        : this.resource.allows(action, item);
    },
  },
};
</script>

<template>
  <v-card>
    <v-card-title class="grey lighten-4">
      {{ label || title }}

      <v-spacer />

      <v-btn
        v-if="_allows('create') && canCreate !== false"
        :to="linkToCreate"
        text
        color="primary"
        class="mr-3"
      >
        Создать
      </v-btn>

      <slot name="header" />

      <v-text-field
        v-if="!resource.static"
        :value="indexParams.search"
        append-icon="mdi-search"
        label="Поиск"
        class="mr-3"
        dense
        single-line
        hide-details
        solo
        @input="_updateSearch"
      />

      <ResourceActionsMenu :resource-name="resourceName" @choose="runAction(item, $event)">
        <template v-slot="{ on }">
          <v-btn icon v-on="on">
            <v-icon>mdi-dots-vertical</v-icon>
          </v-btn>
        </template>
      </ResourceActionsMenu>

      <ResourceListFilters
        :resource-name="resourceName"
        :view="view"
        :data="filterParams"
        @update="_updateFilter"
        @reset="updateParams(filterDefaults)"
      >
        <template v-slot:default="{ on, filtered }">
          <v-btn :color="filtered ? 'primary' : null" icon title="Фильтры" v-on="on">
            <v-icon>{{ filtered ? "mdi-filter" : "mdi-filter-outline" }}</v-icon>
          </v-btn>
        </template>
      </ResourceListFilters>

      <v-btn icon @click="_downloadCSV" title="Скачать CSV">
        <v-icon>mdi-download</v-icon>
      </v-btn>

      <v-btn v-if="!resource.static" icon title="Обновить данные" @click="load">
        <v-icon>mdi-refresh</v-icon>
      </v-btn>
    </v-card-title>

    <v-data-table
      :items="items"
      :loading="loading"
      :server-items-length="serverItemsLength"
      :sort-by="indexParams.sortBy"
      :sort-desc="indexParams.sortDesc"
      :page="indexParams.page"
      :items-per-page="indexParams.perPage"
      :headers="headers"
      :footer-props="footerProps"
      must-sort
      @update:options="_updateFromTableOptions"
    >
      <template v-slot:item="{ item, headers }">
        <tr :class="_rowClass(item)">
          <td v-for="col in headers" :key="col.value">
            <component
              v-if="col.field"
              v-bind="col.field"
              :data-resource-name="resourceName"
              :is="col.field.component || 'string'"
              :value="getFieldValue(item, col.field)"
              :data="item"
            />

            <template v-else>
              <v-btn
                v-if="_allows('view')"
                :to="_linkToShow(item)"
                color="accent"
                icon
                small
                @click.native="$event.stopImmediatePropagation()"
              >
                <v-icon small>mdi-eye</v-icon>
              </v-btn>

              <v-btn
                v-if="_allows('update', item)"
                :to="_linkToEdit(item)"
                color="primary"
                icon
                small
                @click.native="$event.stopImmediatePropagation()"
              >
                <v-icon small>mdi-pencil</v-icon>
              </v-btn>

              <v-btn v-if="_allows('delete', item)" color="red darken-1" icon small @click.stop="trash(item)">
                <v-icon small>mdi-delete</v-icon>
              </v-btn>

              <ResourceActionsMenu :resource-name="resourceName" :data="item" @choose="runAction(item, $event)">
                <template v-slot:default="{ on }">
                  <v-btn small icon v-on="on">
                    <v-icon small>mdi-dots-vertical</v-icon>
                  </v-btn>
                </template>
              </ResourceActionsMenu>
            </template>
          </td>
        </tr>
      </template>
    </v-data-table>

    <ConfirmationDialog v-model="itemToDelete" @confirm="_confirmTrash">
      Вы действительно хотите удалить эту запись?
    </ConfirmationDialog>

    <FlashMessage v-model="message" :color="messageColor" />

    <ResourceActionsDialog
      v-model="isRunningAction"
      :resource-name="resourceName"
      :data="currentItem"
      :action="currentAction"
      @run="load"
    />
  </v-card>
</template>
