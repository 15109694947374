import Vue from "vue";
import Vuetify from "vuetify/lib";
import ru from "vuetify/es5/locale/ru";
import colors from "vuetify/lib/util/colors";

Vue.use(Vuetify);

export default new Vuetify({
  lang: {
    locales: { ru },
    current: "ru",
  },
  theme: {
    themes: {
      light: {
        primary: colors.lightGreen.darken1,
        secondary: "#7942b3",
        //accent: colors.indigo.base
      },
    },
  },
});
