<script>
import ResourceEdit from '@/views/ResourceEdit.vue';
import ResourceRelatedForm from '@/mixins/ResourceRelatedForm';
import ResourceRelatedPage from '@/mixins/ResourceRelatedPage';

export default {
  name: 'ResourceRelatedEdit',
  extends: ResourceEdit,
  mixins: [ ResourceRelatedForm, ResourceRelatedPage ],

  computed: {
    breadcrumbs() {
      return [
        {
          text: this.parentResource.title,
          to: this.parentResource.allows('viewAny') && this.parentResource.path() || undefined,
          exact: true,
        },

        {
          text: this.parentResource.name(this.parent),
          to: this.parentResource.allows('view') && `${this.parentResource.path()}/${this.parentKey}` || undefined,
          exact: true,
        },

        {
          text: this.relationConfig.label || this.resource.title,
        },

        {
          text: this.resource.name(this.data),
          to: this.relationConfig.allows('view', this.data) && this.relationConfig.path(this.parentKey, this.data) || undefined,
          exact: true,
        },

        { text: "Редактирование" },
      ];
    },
  },

  methods: {
    _xhr (data) {
      return this.$api.patch(this.relationConfig.url(this.parent, this.key), data);
    },

    _redirectUrl (data) {
      return this.relationConfig.allows('view', this.parent, data)
        ? this.relationConfig.path(this.parent, data)
        : this.relationConfig.parentResource().path(this.parent);
    },
  }
}
</script>