export default {
  data: () => ({
    message: null,
    messageColor: "success",
  }),

  methods: {
    flash(message, color = "success") {
      this.messageColor = color;
      this.message = message;
    },
  },
};
