import axios from "axios";
import Vue from "vue";
import auth from "./auth";

axios.defaults.baseURL = process.env.VUE_APP_SERVICE_DOMAIN + "/api/";
axios.defaults.validateStatus = function() {
  return true;
};

export class HttpException {
  constructor(code, data) {
    this.code = code;
    this.data = data;
  }
}

class Api {
  async get(url, params, cancelToken) {
    return this.handleResponse(await axios.get(url, { params, cancelToken }));
  }

  async post(url, data, config) {
    return this.handleResponse(await axios.post(url, data, config));
  }

  async patch(url, data) {
    return this.handleResponse(await axios.patch(url, data));
  }

  async delete(url) {
    return this.handleResponse(await axios.delete(url));
  }

  handleResponse(resp) {
    if (resp.status >= 200 && resp.status < 300) return resp.data;
    if (resp.status === 401) auth.resetToken();

    throw new HttpException(resp.status, resp.data);
  }

  async profile() {
    return await this.get("profile");
  }

  async updateProfile(data) {
    return await this.post("profile", data);
  }

  async index(resource, params, cancelToken) {
    return await this.get(resource, params, cancelToken);
  }

  setToken(token) {
    axios.defaults.headers.common["Authorization"] = "Bearer " + token;
  }
}

const api = new Api();

Vue.prototype.$http = axios;
Vue.prototype.$api = api;

export default api;
