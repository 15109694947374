import rules from "../../core/rules";

function fnsuNotUsed() {
  return this.settings.receipts.service !== "fnsu";
}

function apmCheckNotUsed() {
  return this.settings.receipts.service !== "apmcheck";
}

export default {
  id: "receipts",
  title: "Чеки",
  fields: [
    {
      id: "settings.receipts.service",
      label: "Сервис проверки чеков",
      component: "enum",
      clearable: true,
      placeholder: "Без проверки",
      options: [
        { text: "Ручное заполнение", value: "manual" },
        { text: "Тест", value: "test" },
        { text: "APMCheck", value: "apmcheck" },
        { text: "Неофициальный API ФНС", value: "fnsu" },
      ],
      default: "test",
    },

    {
      id: "settings.apmCheck",
      label: "Доступы к APMCheck",
      component: "header",
      disable: apmCheckNotUsed,
    },

    {
      id: "settings.apmCheck.server",
      label: "Сервер",
      component: "enum",
      options: [{ text: "Основной", value: "https://api.apmcheck.ru" }],
      default: "https://api.apmcheck.ru",
      disable: apmCheckNotUsed,
      rules: [rules.required],
    },

    {
      id: "settings.apmCheck.apiKey",
      label: "Ключ API",
      disable: apmCheckNotUsed,
      inputType: "password",
      rules: [rules.required],
    },

    {
      id: "settings.fnsu",
      label: "Настройки подключения к ФНС",
      component: "header",
      disable: fnsuNotUsed,
    },

    {
      id: "settings.fnsu.phone",
      label: "Номер телефона",
      disable: fnsuNotUsed,
      rules: [rules.required],
    },

    {
      id: "settings.fnsu.password",
      label: "Пароль",
      disable: fnsuNotUsed,
      inputType: "password",
      rules: [rules.required],
    },

    {
      id: "settings.receipts",
      label: "Лимиты",
      component: "header",
    },

    {
      id: "settings.receipts.lifetimeHours",
      label: "Время жизни чека",
      inputType: "number",
      suffix: "часов",
      hint: "Чек должен быть выдан не позднее указанного кол-ва часов",
      rules: [rules.required, rules.min(0)],
    },

    {
      id: "settings.receipts.minPeriodBetweenReceiptsMinutes",
      label: "Минимальное время между чеками",
      inputType: "number",
      suffix: "минут",
      hint: "Между чеками должно пройти как минимум указанное кол-во минут",
      rules: [rules.required, rules.min(0)],
    },

    {
      id: "settings.receipts.periodLimitHours",
      label: "Лимит за период: период",
      inputType: "number",
      suffix: "часов",
      hint: "За указанное кол-во часов можно добавить не более указанного количества чеков",
      rules: [rules.required, rules.min(0)],
    },

    {
      id: "settings.receipts.periodLimitMax",
      label: "Лимит за период: макс. кол-во чеков",
      inputType: "number",
      rules: [rules.required, rules.min(0)],
    },

    {
      id: "settings.receipts.maxPendingHours",
      label: "Макс. время обработки чека",
      inputType: "number",
      suffix: "часов",
      hint:
        "Чеки будут отклоняться, если после отправки на внешний" +
        " сервис чек обрабатывается больше указанного кол-ва часов",
      rules: [rules.required, rules.min(0)],
    },

    {
      id: "settings.disputes.maxDisputesPerReceipt",
      label: "Макс. диспутов на чек",
      inputType: "number",
      rules: [rules.required, rules.min(0)],
    },

    {
      id: "settings.receipts.doubleCheck",
      label: "Автоматически создавать диспут, если не начисляется кэшбэк по новому чеку",
      component: "boolean",
    },
  ],
};
