<script>
export default {
  name: "ConfirmationDialog",
  props: {
    value: null,

    title: {
      type: String,
      default: "Подтвердите действие",
    },
  },

  methods: {
    confirm() {
      this.$emit("confirm");
      this.$emit("input", null);
    },

    cancel() {
      this.$emit("cancel");
      this.$emit("input", null);
    },
  },
};
</script>

<template>
  <v-dialog :value="value" max-width="450px" persistent>
    <v-card>
      <v-card-title>{{ title }}</v-card-title>
      <v-card-text><slot /></v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn color="warning" @click="confirm">Подвердить</v-btn>
        <v-btn @click="cancel">Отмена</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
