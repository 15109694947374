<script>
import FormInput from "../../mixins/FormInput";

export default {
  name: "EnumInput",
  extends: FormInput,
  props: ["options", "multiple"],
};
</script>

<template>
  <v-select v-bind="inputOptions" :value="value" :items="options" :multiple="multiple" @input="$emit('input', $event)">
    <template v-if="multiple" v-slot:selection="{ item }">
      <v-chip :color="item.color" small>{{ item.text }}</v-chip>
    </template>
  </v-select>
</template>
