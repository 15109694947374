import { targetType } from '@/resources/enums';
import rules from '@/core/rules';

export default {
  title: "Галерея",
  singularTitle: "Картинка галереи",
  static: true,
  permissions: {
    viewAny: false,
    delete: true,
    update: false,
    create: true,
  },

  fields: [
    { id: "id", label: "ID", component: "primary" },

    {
      id: "parent_type",
      label: "Тип владельца",
      hideFromDetails: true,
      component: "enum",
      options: [
        { text: "Товар", value: "product" },
        { text: "Раздел каталога", value: "catalogCategory" },
      ],
      sortable: false,
      rules: [rules.required],
    },

    {
      id: "parent_id",
      label: "Владелец",
      component: "morph-to",
      relation: "parent",
      morphKeyName: "parent_type",
      hideFromDetails: true,
      morphMap: {
        product: "products",
        catalogCategory: "catalogCategories",
      },
      rules: [rules.required],
      sortable: false,
    },

    {
      id: "url",
      label: "URL",
      hideFromIndex: true,
      hideFromDetails: true,
      rules: [rules.required],
      sortable: false,
      hint: "Ссылка на фото",
    },

    {
      id: "url",
      label: "Фото",
      component: "photo",
    },

    {
      id: "sort",
      label: "Сортировка",
      component: "numeric",
      hint: "Чем больше, тем ниже будет фото",
      default: 0,
      rules: [ rules.required, rules.integer ]
    },
  ]
}