import rules from "@/core/rules";

export default function(notUsed) {
  return [
    {
      id: "settings.yookassa",
      label: "Настройки Юkassa",
      component: "header",
      disable: notUsed,
    },

    {
      id: "settings.yookassa.agentId",
      label: "Agent ID",
      inputType: "number",
      rules: [rules.required],
      disable: notUsed,
    },

    {
      id: "settings.yookassa.cert",
      label: "Сертификат",
      component: "text-input",
      rules: [rules.required],
      disable: notUsed,
    },

    {
      id: "settings.yookassa.privateKey",
      label: "Приватный ключ",
      component: "text-input",
      rules: [rules.required],
      disable: notUsed,
    },

    {
      id: "settings.yookassa.passphrase",
      label: "Пароль приватного ключа",
      inputType: "password",
      disable: notUsed,
    },
  ];
}
