import moment from "moment";
import { receiptRejectReason, receiptState } from './enums';
import rules from "../core/rules";
import { isManualReceiptService } from '@/resources/settings/utils';

function recognized() {
  return this.state !== "notRecognized";
}

function fiscalUsed(params) {
  return (params.fiscal || "").length > 0;
}

export function allowWhenPending() {
  return this.permissions.updateItems;
}

export default {
  title: "Фото",
  singularTitle: "Фото",
  sortBy: "id",
  sortDesc: true,
  icon: "mdi-receipt",
  permissions: {
    create: true,
  },

  fields: [
    { label: "ID", id: "id", component: "primary" },
    { label: "Сумма", id: "sum", disable: true },
    { label: "Время", id: "time", component: "datetime", disable: true },

    {
      label: "Фото",
      id: "image_url",
      component: "photo",
      hideFromIndex: true,
      disable: true,
    },

    {
      label: "Комментарий",
      id: "comment",
      disable: true,
      component: "text-input"
    },

    {
      label: "Участник",
      id: "client_id",
      component: "belongs-to",
      resourceName: "clients",
      relation: "client",
      rules: [rules.required],
      sortable: false,
    },

    {
      id: "n",
      label: "Номер",
      disable: true,
    },

    {
      id: "state",
      label: "Состояние",
      component: "enum",
      options: receiptState,
      sortable: false,
      disable: true,
    },

    { label: "Кэшбэк", id: "client_cash_back", component: "numeric", disable: true },
    { label: "Изменение кэшбэка", id: "client_cash_back_change", component: "numeric", disable: true },
    { label: "Сумма реферальных наград", id: "referral_total_rewards", component: "numeric", disable: true },
    { id: "disputes_count", label: "Кол-во диспутов", component: "numeric", disable: true },
    { label: "Кэшбэк партнеру", id: "partner_cash_back", component: "numeric", disable: true },

    {
      label: "Дата обновления кэшбэка",
      id: "cash_back_updated_at",
      component: "date",
      disable: true,
    },

    {
      id: "fiscal",
      label: "Фискальные данные",
      component: "header",
      disable: true,
    },

    {
      label: "ФН",
      id: "fn",
      hideFromIndex: true,
      rules: [rules.required, rules.digits],
    },

    {
      label: "ФП",
      id: "fp",
      hideFromIndex: true,
      rules: [rules.required, rules.digits],
    },

    {
      label: "ФД",
      id: "fd",
      hideFromIndex: true,
      rules: [rules.required, rules.digits],
    },

    { label: "Сумма", id: "sum", rules: [rules.required], hide: true },

    {
      label: "Время",
      id: "time",
      component: "datetime",
      rules: [rules.required],
      hide: true,
    },

    {
      id: "image_url",
      label: "Ссылка на изображение",
      hint: "Вставьте сюда ссылку на оригинальное изображение чека",
      hide: true,
    },

    {
      id: "fiscal_string",
      label: "Фискальная строка",
      component: "computed",
      hideFromIndex: true,
      getter: function() {
        const t = moment(this.time).format("YYYYMMDDTHHmm");

        return this.sum && this.fn && this.fd && this.fp
          ? `t=${t}&s=${this.sum}&fn=${this.fn}&i=${this.fd}&fp=${this.fp}&n=1`
          : null;
      },
    },

    { id: "other", component: "header", label: "Другое", disable: true },

    {
      id: "external_system",
      label: "Внешняя система",
      hideFromIndex: true,
      disable: true,
      sortable: false,
    },

    {
      id: "external_id",
      label: "ID внешней системы",
      hideFromIndex: true,
      disable: true,
      sortable: false,
    },

    {
      label: "Создан",
      id: "created_at",
      component: "datetime",
      disable: true,
    },
  ],

  filters: [{ id: "state", label: "Состояние", component: "enum", options: receiptState, clearable: true }],

  relations: [
    {
      id: "items",
      label: "Позиции",
      resourceName: "receiptItems",
      foreignKey: "receipt_id",
      relation: "items",
      nested: true,
      permissions: {
        create: allowWhenPending,
        update: allowWhenPending,
        delete: allowWhenPending,
      },
    },

    {
      id: "disputes",
      label: "Диспуты",
      resourceName: "disputes",
      relation: "disputes",
      foreignKey: "receipt_id",
    },

    {
      id: "transactions",
      resourceName: "transactions",
      foreignKey: "receipt_id",
      relation: "transactions",
      create: false,
    },
  ],

  actions: [
    {
      id: "applyPromotions",
      title: "Применить акции",
      hide() { return !this.permissions?.applyPromotions; },
      async handler(api) {
        await api.post(`receipts/${this.id}/applyPromotions`);
      },
    },

    {
      id: "reject",
      title: "Отклонить",
      fields: [
        {
          id: "reason",
          label: "Причина",
          default: "rejected",
          component: "enum",
          options: receiptRejectReason,
        },
      ],
      hide: recognized,
      async handler(api, params) {
        await api.post(`receipts/${this.id}/reject`, params);
      },
    },

    {
      id: "fiscal",
      title: "Заполнить",
      hide: recognized,
      fields: [
        {
          id: "fiscal",
          label: "Фискальная строка",
          placeholder: "t=20200225T1306&s=1038.00&fn=9281000100292020&i=87916&fp=3976311328&n=1",
          hint: "Заполните это поле или все остальные поля",
        },

        {
          id: "fn",
          label: "ФН",
          disable: fiscalUsed,
          rules: [rules.required, rules.digits],
        },

        {
          id: "fp",
          label: "ФП",
          disable: fiscalUsed,
          rules: [rules.required, rules.digits],
        },

        {
          id: "fd",
          label: "ФД",
          hint: "Введите без нулей вначале",
          disable: fiscalUsed,
          rules: [rules.required, rules.digits],
        },

        { id: "sum", label: "Сумма", rules: [rules.required], disable: fiscalUsed },

        {
          id: "time",
          label: "Время",
          component: "datetime",
          rules: [rules.required],
          disable: fiscalUsed,
        },
      ],

      async handler(api, params) {
        await api.post(`receipts/${this.id}/fiscal`, params);
      },
    },
  ],
};
