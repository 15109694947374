<script>
export default {
  name: "BooleanField",
  inheritAttrs: false,
  props: ["value"],
};
</script>

<template>
  <v-icon v-if="value" color="success">mdi-check-circle</v-icon>
  <v-icon v-else color="danger">mdi-close-circle</v-icon>
</template>
