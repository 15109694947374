export default {
  id: "referral",
  title: "Реферальная программа",
  fields: [
    {
      id: "settings.referral.rewardsByLevel",
      label: "Вознаграждения по уровням",
      hint: "Укажите числа в процентах для каждого уровня через запятую. Пример: 15,10,5,1. Оставьте поле пустым, чтобы отключить вознаграждения."
    }
  ]
}