import rules from "../core/rules";

export default {
  title: "Товары для акций",
  singularTitle: "Товар для акции",
  name: "name",
  icon: "mdi-cart-outline",
  serverOptions: true,
  permissions: {
    update: true,
    create: true,
  },

  fields: [
    { id: "id", label: "ID", component: "primary" },
    { id: "name", label: "Название", max: 255, rules: [rules.required] },

    {
      id: "preview_description",
      label: "Краткое описание",
      component: "text-input",
      max: 1000,
      sortable: false,
    },

    {
      id: "detail_description",
      component: "text-input",
      label: "Описание",
      max: 65535,
      hideFromIndex: true,
      sortable: false,
    },

    {
      id: "external_link",
      component: "text-input",
      label: "Внешняя ссылка",
      max: 1000,
      hideFromIndex: true,
      sortable: false,
    },

    { id: "is_active", label: "Активно", component: "boolean", default: true },

    {
      label: "Дата создания",
      id: "created_at",
      component: "date",
      disable: true,
    },
  ],

  relations: [
    {
      id: "gallery",
      label: "Галерея",
      resourceName: "galleryImages",
      foreignKey: "parent_id",
      morphKeyName: "parent_type",
      morphKey: "product",
      relation: "gallery",
    },

    {
      id: "patterns",
      label: "Мэтчэры",
      resourceName: "productPatterns",
      foreignKey: "product_id",
      relation: "patterns",
    },
  ],
};
