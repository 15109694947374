<script>
import _ from "lodash";

export default {
  name: "DistributionReport",
  props: {
    value: Array,
    valueKey: String,
    idKey: String,
    options: Array,
  },

  computed: {
    items() {
      return this.value
        .map(i => {
          const id = i[this.idKey];
          const value = parseFloat(i[this.valueKey]);
          const option = this.optionsMap[id] || {};

          return {
            id,
            value,
            label: option.text || id,
            color: option.color || "gray",
            percentage: ((value / this.total) * 100).toFixed(2) + "%",
          };
        })
        .sort((a, b) => (a.value < b.value ? -1 : a.value === b.value ? 0 : 1));
    },

    total() {
      return this.value.reduce((value, i) => value + i[this.valueKey], 0);
    },

    optionsMap() {
      return _.keyBy(this.options || {}, "value");
    },
  },
};
</script>

<template>
  <v-card dense>
    <v-card-text>
      <div class="overline font-weight-medium"><slot /></div>
      <p v-if="!value.length" class="headline">Нет данных</p>
      <div v-else v-for="item in items" :key="item.id">
        <v-icon :color="item.color" x-small>mdi-square</v-icon>
        <span class="caption"> {{ item.label }} ({{ item.value }} &mdash; {{ item.percentage }}) </span>
      </div>
    </v-card-text>
  </v-card>
</template>
