import _ from "lodash";
import Vue from "vue";

export function value(value, thisArg, ...params) {
  return typeof value === "function" ? value.apply(thisArg, params) : value;
}

export function difference(object, base) {
  function changes(object, base) {
    return _.transform(object, function(result, value, key) {
      if (!_.isEqual(value, base[key])) {
        result[key] =
          _.isObject(value) && _.isObject(base[key])
            ? changes(value, base[key])
            : value;
      }
    });
  }

  return changes(object, base);
}

export function setPath(object, path, value) {
  path = _.toPath(path);
  do {
    const key = path.shift();

    if (!path.length) {
      Vue.set(object, key, value);
    } else if (!object.hasOwnProperty(key)) {
      Vue.set(object, key, {});
    }

    object = object[key];
  } while (path.length);
}

export function randomString(length) {
  let result = "";
  const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  const charactersLength = characters.length;

  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }

  return result;
}
