<script>
import fields from "./form";
import HasGetter from "../mixins/HasGetter";
import FieldWrap from "./FieldWrap";
import { setPath } from '@/utils';

export default {
  name: "FormFields",
  mixins: [HasGetter],
  components: { ...fields, FieldWrap },
  props: {
    title: String,

    fields: {
      type: Array,
      required: true,
    },

    data: {
      type: Object,
      required: true,
    },

    disabledFields: Object,

    errors: {
      type: Object,
      default: () => ({}),
    },

    errorsPrefix: {
      type: String,
      default: "",
    },

    loading: null,
  },

  methods: {
    setFieldValue(field, value) {
      if (typeof field.setter === "function") {
        field.setter.call(this.data, value);
      } else {
        setPath(this.data, field.id, value === "" || value === undefined ? null : value);
      }

      this.$emit("update", field, value);
    },
  },
};
</script>

<template>
  <v-card :loading="loading">
    <v-card-title class="grey lighten-4">
      {{ title }}
      <slot name="title" />
    </v-card-title>

    <slot />

    <v-card-text class="pt-3">
      <template v-for="field in fields">
        <div v-if="field.component === 'header'" :key="field.id" class="my-3">
          <div class="headline font-weight-light">{{ field.label }}</div>
          <v-divider />
          <div v-if="field.hint" class="caption">{{ field.hint }}</div>
        </div>

        <FieldWrap v-else :key="field.id" v-bind="field">
          <v-skeleton-loader v-if="loading" type="chip" />
          <component
            v-else
            v-bind="field"
            :value="getFieldValue(data, field)"
            :is="field.component || 'string'"
            :data="data"
            :errors="errors[`${errorsPrefix}${field.id}`]"
            :disabled="disabledFields && disabledFields[field.id]"
            @input="setFieldValue(field, $event)"
          />
        </FieldWrap>
      </template>
    </v-card-text>

    <slot name="footer" />
  </v-card>
</template>
