import Vue from "vue";
import { randomString } from "../utils";
import axios from "axios";

const ACCESS_TOKEN_PARAM = "__accessToken";
const RETURN_URL_PARAM = "__returnUrl";

class Auth {
  constructor() {
    this.storage = localStorage;
    this.redirectUri = process.env.VUE_APP_OAUTH_REDIRECT_URI;
    this.clientId = process.env.VUE_APP_CLIENT_ID;
    this.authorizeUrl = process.env.VUE_APP_SERVICE_DOMAIN + "/oauth/authorize";
    this.tokenUrl = process.env.VUE_APP_SERVICE_DOMAIN + "/oauth/token";
    this.logoutUrl = process.env.VUE_APP_SERVICE_DOMAIN + "/api/logout";
    this.logoutRedirectUrl = process.env.VUE_APP_SERVICE_DOMAIN + "/logout";
  }

  isSupported() {
    return crypto !== undefined && crypto.subtle !== undefined && this.storage;
  }

  isAuthenticated() {
    return !!this.getToken();
  }

  getToken() {
    return this.storage.getItem(ACCESS_TOKEN_PARAM);
  }

  resetToken() {
    this.storage.removeItem(ACCESS_TOKEN_PARAM);
  }

  getReturnUrl() {
    return this.storage.getItem(RETURN_URL_PARAM);
  }

  async login(returnUrl) {
    const state = randomString(32);
    const codeVerifier = randomString(128);
    const codeChallenge = btoa(await this._hash(codeVerifier))
      .replace(/=/g, "")
      .replace(/\+/g, "-")
      .replace(/\//g, "_");

    this.storage.setItem("codeVerifier", codeVerifier);
    this.storage.setItem("state", state);

    if (returnUrl) {
      this.storage.setItem(RETURN_URL_PARAM, returnUrl);
    } else {
      this.storage.removeItem(RETURN_URL_PARAM);
    }

    const entry = new URL(this.authorizeUrl);

    entry.search = new URLSearchParams({
      client_id: this.clientId,
      redirect_uri: this.redirectUri,
      response_type: "code",
      scope: "*",
      state: state,
      code_challenge: codeChallenge,
      code_challenge_method: "S256",
    });

    location.assign(entry);
  }

  async _hash(value) {
    const msgUint8 = new TextEncoder().encode(value);
    const hashBuffer = await crypto.subtle.digest("SHA-256", msgUint8);
    const hashArray = Array.from(new Uint8Array(hashBuffer));

    return hashArray.map(b => String.fromCharCode(b)).join("");
  }

  async handleResponse(params) {
    if (!params.state || !params.code) {
      throw new Error("Invalid params");
    }

    if (params.state !== this.storage.getItem("state")) {
      throw new Error("Invalid state");
    }

    const resp = await axios.post(this.tokenUrl, {
      client_id: this.clientId,
      redirect_uri: this.redirectUri,
      grant_type: "authorization_code",
      code_verifier: this.storage.getItem("codeVerifier"),
      code: params.code,
    });

    if (resp.status !== 200) {
      throw new Error("Failed to get token");
    }

    const accessToken = resp.data.access_token;

    this.storage.setItem(ACCESS_TOKEN_PARAM, accessToken);

    return accessToken;
  }

  async logout() {
    const { status } = await axios.post(this.logoutUrl);

    if (status !== 200) {
      throw new Error("Failed to logout");
    }

    this.resetToken();

    location.href = this.logoutRedirectUrl;
  }
}

const auth = (Vue.prototype.$auth = new Auth());

export default auth;
