<script>
import ResourceChip from "../ResourceChip";

export default {
  name: "HasManyNested",
  components: { ResourceChip },
  props: ["value", "resourceName"],
};
</script>

<template>
  <span v-if="!value || !value.length">&mdash;</span>
  <v-chip-group v-else column>
    <ResourceChip v-for="item in value" :key="item.id" :resource-name="resourceName" :value="item" />
  </v-chip-group>
</template>
