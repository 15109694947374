<script>
import _ from "lodash";
import FormInput from "../../mixins/FormInput";
import resources from "../../resources";

export default {
  name: "BelongsToInput",
  extends: FormInput,
  props: {
    staticFilter: {
      type: Object,
      default: () => ({}),
    },
  },

  data: () => ({
    loading: false,
    serverItems: null,
    search: null,
  }),

  computed: {
    resource() {
      return resources[this.resourceName];
    },

    resourceOptions() {
      return this.$store.state.resources[this.resourceName] || {};
    },

    options() {
      const options = this.serverItems || this.resourceOptions.items || [];

      if (this.key && !options.find(o => o.id === this.key)) {
        options.push({ id: this.key, text: this.value.toString() });
      }

      return options;
    },

    key() {
      return this.resource && this.resource.key(this.value);
    },

    server() {
      return this.resource && this.resource.serverOptions;
    },
  },

  watch: {
    search: {
      handler: _.debounce(function() {
        this.load();
      }, 500),
    },

    resource: {
      immediate: true,
      handler: function() {
        if (this.server) {
          this.load(this.value);
        }
      },
    },
  },

  methods: {
    async load(search) {
      if (!this.server) {
        this.serverItems = null;

        return;
      }

      if (this.search === ((this.$refs.select || {}).selectedItem || {}).text) {
        return;
      }

      try {
        this.loading = true;

        const resp = await this.$api.index(this.resource.url(), {
          search: search || this.search,
          per_page: 50,
          ...this.staticFilter,
        });

        this.serverItems = resp.data.map(i => ({
          id: this.resource.key(i),
          text: this.resource.name(i),
        }));
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<template>
  <v-autocomplete
    v-if="resource"
    v-bind="inputOptions"
    :value="key"
    :items="options"
    :loading="loading || resourceOptions.loading"
    :search-input.sync="search"
    :hide-no-data="server"
    :placeholder="server ? 'Начните вводить для поиска' : null"
    no-filter
    item-value="id"
    ref="select"
    @input="$emit('input', $event)"
  />
</template>
