export default {
  static: true,
  permissions: {
    view: false,
  },

  fields: [
    { id: "index", component: "primary", label: "#" },

    {
      id: "product",
      label: "Товар",
      component: "belongs-to",
      relation: "product",
      resourceName: "products",
    },

    {
      id: "qty",
      label: "Кол-во",
    },

    {
      id: "price",
      label: "Цена",
    },

    {
      id: "total",
      label: "Сумма",
    }
  ]
}