<script>
import ResourceList from "../components/ResourceList";
import resources from "../resources";

export default {
  name: "ResourcePage",
  components: { ResourceList },
  computed: {
    resourceName() {
      return this.$route.params.resource;
    },

    view() {
      return this.$route.params.view;
    },

    breadcrumbs() {
      const resource = resources[this.resourceName];

      return [
        {
          text: resource.title,
          to: resource.path(),
          exact: true,
        },

        {
          text: resource.views[this.view].title,
        },
      ];
    },
  },
};
</script>

<template>
  <v-skeleton-loader :loading="!$store.state.initialized" type="card">
    <v-breadcrumbs :items="breadcrumbs" class="pl-0" large />

    <ResourceList :resource-name="resourceName" :key="`${resourceName}_${view}`" :view="view" />
  </v-skeleton-loader>
</template>
