<script>
export default {
  name: "CustomElement",
  functional: true,
  props: {
    value: Object,
  },

  render (cel, { props }) {
    function renderElement (el) {
      if (typeof el === "string") return el;

      return cel(el.component, el.data, (el.children||[]).map(renderElement));
    }

    return renderElement(props.value);
  }
}
</script>