export default {
  inheritAttrs: false,
  props: {
    value: null,
    data: Object,
    label: String,
    hint: String,
    rules: Array,
    errors: Array,
    clearable: null,
    placeholder: null,
    disabled: Boolean,
  },

  computed: {
    inputOptions() {
      return {
        rules: this.rules,
        errorMessages: this.errors,
        outlined: true,
        dense: true,
        solo: true,
        hideDetails: "auto",
        clearable: this.clearable,
        placeholder: this.placeholder,
        disabled: this.disabled,
      };
    },
  },
};
