export const receiptRejectReason = [
  { value: "rejected", text: "Отклонен", color: "red darken-2 white--text" },
  { value: "duplicate", text: "Дубль", color: "red darken-2 white--text" },
  { value: "unreadable", text: "Нечитаемый", color: "red darken-2 white--text" },
];

export const receiptState = [
  { value: "new", text: "Новый" },
  { value: "pending", text: "Обработка", color: "yellow" },
  { value: "notRecognized", text: "Не распознан", color: "yellow darken-2" },
  { value: "accepted", text: "Принят", color: "yellow" },
  { value: "beingChecked", text: "На проверке", color: "yellow" },
  {
    value: "cashBackCredited",
    text: "Кэшбэк начислен",
    color: "green white--text",
  },
  {
    value: "noCashBack",
    text: "Кэшбэк не начислен",
    color: "red lighten-3",
  },
  { value: "tooOld", text: "Старый", color: "red darken-2 white--text" },
  {
    value: "tooOften",
    text: "Частые покупки",
    color: "red darken-2 white--text",
  },
  { value: "expired", text: "Истек", color: "red darken-2 white--text" },
].concat(receiptRejectReason);

export const payoutService = [
  { value: "winpay", text: "WinPay" },
  { value: "yookassa", text: "YooKassa" },
];

export const couponsService = [
  { value: "pharmahrm", text: "PharmaHRM" }
];

export const targetType = [
  { value: "client", text: "Участник", color: "green" },
  { value: "partner", text: "Партнер", color: "yellow" },
];

export const transactionType = [
  { value: "cashBack", text: "Зачисление кэшбэка", color: "green" },
  {
    value: "cashBackCorrection",
    text: "Коррекция кэшбэка",
    color: "green lighten-4",
  },
  { value: "reward", text: "Награда", color: "purple lighten-3" },
  {
    value: "rewardCorrection",
    text: "Коррекция награды",
    color: "purple lighten-5",
  },
  { value: "payout", text: "Выплата", color: "yellow" },
  { value: "payoutCancel", text: "Отмена выплаты", color: "yellow darken-4" },
  { value: "debit", text: "Списание", color: "red" },
  { value: "credit", text: "Зачисление", color: "green" },
];

export const disputeState = [
  { value: "new", text: "Новый" },
  { value: "pending", text: "На рассмотрении", color: "yellow" },
  { value: "resolved", text: "Решен", color: "green" },
  { value: "declined", text: "Отклонен", color: "red" },
];

export const disputeSource = [
  { value: "client", text: "Клиент", color: "green" },
  { value: "manager", text: "Менеджер", color: "yellow" },
  { value: "system", text: "Система", color: "orange" },
];

export const predefinedPeriods = [
  { text: "Свой", value: null },
  { text: "7 дней", value: "week" },
  { text: "Текущая неделя", value: "this_week" },
  { text: "Текущая неделя до сегодня", value: "this_week_so_far" },
  { text: "1 месяц", value: "month" },
  { text: "Текущий месяц", value: "this_month" },
  { text: "Текущий месяц до сегодня", value: "this_month_so_far" },
  { text: "1 год", value: "year" },
  { text: "Текущий год", value: "this_year" },
  { text: "Текущий год до сегодня", value: "this_year_so_far" },
];

export const couponActivationState = [
  { text: "Не активирован", value: "notActivated" },
  { text: "Ожидает проверки", value: "pending", color: "yellow" },
  { text: "Активирован", value: "successful", color: "green" },
  { text: "Отклонен", value: "declined", color: "red" },
];

export const productPatternType = [
  { text: "Внешний ID", value: "matchExternalId" },
  { text: "Регулярное выражение MySQL", value: "matchRegexp" },
];

export const productType = [
  { text: "Акционный", value: "promotional" },
  { text: "Приз", value: "prize" },
];

export const orderState = [
  { text: "Новый", value: "new", color: "blue" },
  { text: "Отправлен", value: "sent", color: "yellow" },
  { text: "Доставлен", value: "delivered", color: "green" },
  { text: "Отменен", value: "cancelled", color: "red" },
  { text: "Отклонен", value: "rejected", color: "red" },
];