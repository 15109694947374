<script>
import BaseBelongsTo from "./BaseBelongsTo";

export default {
  name: "MorphToInput",
  extends: BaseBelongsTo,
  props: {
    morphKeyName: {
      type: String,
      required: true,
    },

    morphMap: {
      type: Object,
      required: true,
    },
  },

  computed: {
    type() {
      return this.data[this.morphKeyName];
    },

    resourceName() {
      return this.morphMap[this.type];
    },
  },

  watch: {
    resourceName: {
      immediate: true,
      handler: function(v) {
        if (!this.server) {
          this.$store.dispatch("initResourceOptions", v);
        }
      },
    },
  },
};
</script>
