<script>
import ResourceChip from "../ResourceChip";
import _ from "lodash";

export default {
  inheritAttrs: false,
  components: { ResourceChip },
  props: {
    relation: String,
    value: null,
    data: null,
  },

  computed: {
    relationValue() {
      return this.relation && _.get(this.data, this.relation);
    },
  },
};
</script>

<template>
  <span v-if="!value && !relationValue">&mdash;</span>
  <ResourceChip v-else :resource-name="resourceName" :value="relationValue" :id="value" />
</template>
