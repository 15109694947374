import resources from '@/resources';

export default {
  computed: {
    parentResourceName() {
      return this.$route.params.resource;
    },

    parentResource() {
      return (this.parentResourceName && resources[this.parentResourceName]) || {};
    },

    relationName() {
      return this.$route.params.relation;
    },

    parentKey() {
      return this.$route.params.parentKey;
    },

    relationConfig() {
      return this.parentResource.relationById(this.relationName);
    },

    resourceName() {
      return this.relationConfig.resourceName;
    },
  },

  methods: {
    _url() {
      return this.relationConfig.url(this.parentKey);
    },

    _filterField (f) {
      return f.id === this.relationConfig?.foreignKey || f.id === this.relationConfig?.morphKeyName;
    },
  }
}