<script>
import BaseBelongsTo from "./BaseBelongsTo";

export default {
  name: "BelongsToInput",
  extends: BaseBelongsTo,
  props: {
    resourceName: {
      type: String,
      required: true,
    },
  },

  created() {
    if (!this.server) this.$store.dispatch("initResourceOptions", this.resourceName);
  },
};
</script>
