import { payoutService } from "../enums";
import rules from "@/core/rules";
import winpay from "./payouts/winpay";
import yookassa from "./payouts/yookassa";

function serviceNotUsed(service) {
  return function() {
    return this.settings.payouts.mobilePhone.service !== service && this.settings.payouts.bankCard.service !== service;
  };
}

export default {
  id: "payouts",
  title: "Выплаты",
  hint: "Выплаты бывают на телефон или на карту. Для каждого типа указываются свои настройки.",
  fields: [
    {
      id: "settings.payouts.mobilePhone",
      label: "Выплаты на мобильный телефон",
      component: "header",
    },

    {
      id: "settings.payouts.mobilePhone.service",
      label: "Сервис",
      component: "enum",
      options: payoutService,
      clearable: true,
      placeholder: "Без сервиса"
    },

    {
      id: "settings.payouts.mobilePhone.minAmount",
      label: "Минимальная сумма",
      inputType: "number",
      suffix: "баллов",
      rules: [rules.min(0)],
      disable: function () {
        return this.settings.payouts.mobilePhone.service !== null;
      }
    },

    {
      id: "settings.payouts.bankCard",
      label: "Выплаты на банковскую карту",
      component: "header",
    },

    {
      id: "settings.payouts.bankCard.service",
      label: "Сервис",
      component: "enum",
      options: payoutService,
      clearable: true,
      placeholder: "Без сервиса",
    },

    {
      id: "settings.payouts.bankCard.minAmount",
      label: "Минимальная сумма",
      inputType: "number",
      suffix: "баллов",
      rules: [rules.min(0)],
      disable: function () {
        return this.settings.payouts.bankCard.service !== null;
      }
    },

    ...winpay(serviceNotUsed("winpay")),
    ...yookassa(serviceNotUsed("yookassa")),
  ],
};
