export default {
  url: "coupons/series",
  title: "Серии купонов",
  singularTitle: "Серия купонов",
  name: "n",
  permissions: {
    create: true,
    update: true,
  },

  fields: [
    { id: "id", label: "ID", component: "primary" },
    { id: "n", label: "Серия", inputType: "number", disableForUpdate: true },

    {
      id: "partner_id",
      label: "Партнер",
      component: "belongs-to",
      relation: "partner",
      resourceName: "partners",
    },

    { id: "is_active", label: "Активно", component: "boolean", default: true },

    { label: "Дата создания", id: "created_at", component: "date", disable: true },
    { label: "Дата обновления", id: "updated_at", component: "date", disable: true },
  ],

  relations: [
    {
      id: "coupons",
      label: "Купоны",
      resourceName: "coupons",
      foreignKey: "series_id",
      relation: "coupons",
    },
  ],
};
