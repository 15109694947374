<script>
import resources from "../resources";

export default {
  name: "ResourceChip",
  inheritAttrs: false,
  props: ["resourceName", "value", "id"],

  computed: {
    resource() {
      return resources[this.resourceName] || {};
    },

    name() {
      if (this.value) {
        return this.resource.name(this.value);
      } else {
        const options = this.$store.state.resources[this.resourceName];

        if (!options) return this.id;

        return (options.items.find(i => i.id === this.id) || {}).text || this.id;
      }
    },

    to() {
      return this.value ? this.resource.path(this.value) : this.resource.path() + "/" + this.id;
    },
  },
};
</script>

<template>
  <v-chip
    v-if="resource.allows('view')"
    :to="to"
    color="primary"
    outlined
    link
    small
    @click.native="$event.stopImmediatePropagation()"
  >
    {{ name }}
  </v-chip>
  <span v-else>{{ name }}</span>
</template>
