import rules from "@/core/rules";

export default function(notUsed) {
  return [
    {
      id: "settings.winpay",
      label: "Настройки WinPay",
      component: "header",
      disable: notUsed,
    },

    {
      id: "settings.winpay.serviceId",
      label: "Service ID",
      inputType: "number",
      rules: [rules.required],
      disable: notUsed,
    },

    {
      id: "settings.winpay.clientSecret",
      label: "Client secret",
      inputType: "password",
      rules: [rules.required],
      disable: notUsed,
    },

    {
      id: "settings.winpay.testMode",
      label: "В режиме тестирования",
      component: "boolean",
      disable: notUsed,
    },
  ];
}
