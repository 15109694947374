<script>
import resources from "../resources";

export default {
  name: "MenuResourceItem",
  props: ["resourceName"],
  computed: {
    resource() {
      return resources[this.resourceName];
    },
  },
};
</script>

<template>
  <v-hover v-if="$store.state.initialized" v-slot:default="{ hover }">
    <v-list-item v-if="resource.allows('viewAny')" :to="`/${resourceName}`" link>
      <v-list-item-action>
        <v-icon>{{ resource.icon }}</v-icon>
      </v-list-item-action>

      <v-list-item-content>
        <v-list-item-title>{{ resource.title }}</v-list-item-title>
      </v-list-item-content>

      <v-list-item-action v-if="resource.allows('create')" :style="{ opacity: hover ? undefined : 0 }">
        <v-btn :to="`/${resourceName}/create`" color="primary" icon small>
          <v-icon small>mdi-plus-thick</v-icon>
        </v-btn>
      </v-list-item-action>
    </v-list-item>
  </v-hover>
  <v-skeleton-loader v-else type="list-item-avatar" />
</template>
