import rules from "../core/rules";

export default {
  title: "Призы",
  singularTitle: "Приз",
  name: "name",
  icon: "mdi-cart-outline",
  serverOptions: true,
  permissions: {
    update: true,
    create: true,
  },

  fields: [
    { id: "id", label: "ID", component: "primary" },
    { id: "name", label: "Название", max: 255, rules: [rules.required] },

    {
      id: "preview_description",
      label: "Краткое описание",
      component: "text-input",
      max: 1000,
      sortable: false,
    },

    {
      id: "detail_description",
      component: "text-input",
      label: "Описание",
      max: 65535,
      hideFromIndex: true,
      sortable: false,
    },

    {
      id: "price",
      label: "Стоимость",
      component: "numeric",
      rules: [ rules.digits, rules.min(0) ],
    },

    {
      id: 'catalogCategories',
      label: 'Разделы каталога',
      component: "belongs-to-many-nested",
      resourceName: "catalogCategories",
      hideFromIndex: true,
    },

    { id: "is_active", label: "Активно", component: "boolean", default: true },

    {
      label: "Дата создания",
      id: "created_at",
      component: "date",
      disable: true,
    },
  ],

  relations: [
    {
      id: "gallery",
      label: "Галерея",
      resourceName: "galleryImages",
      foreignKey: "parent_id",
      morphKeyName: "parent_type",
      morphKey: "product",
      relation: "gallery",
    },
  ],
};
