<script>
import _ from "lodash";
import profileFields from "../resources/profile";
import FormFields from "../components/FormFields";
import { difference, setPath, value } from '@/utils';
import FlashMessage from "../components/FlashMessage";
import ResourceForm from "../mixins/ResourceForm";
import ConfirmationDialog from "../components/ConfirmationDialog";

export default {
  name: "ProfilePage",
  extends: ResourceForm,
  components: { FlashMessage, FormFields, ConfirmationDialog },

  data: () => ({
    data: {},
    loading: false,
  }),

  computed: {
    title() {
      return "Настройки профиля";
    },

    fields() {
      return profileFields;
    },

    filteredFields() {
      return this.fields.filter(f => value(f.disable, this.data) !== true);
    },

    hint() {
      return null;
    },
  },

  watch: {
    tabName: {
      immediate: true,
      handler: function() {
        this._syncData();
      },
    },
  },

  beforeRouteLeave(to, from, next) {
    this._handleLeave(next);
  },

  beforeRouteUpdate(to, from, next) {
    this._handleLeave(next);
  },

  created() {
    this.$store.watch(
      s => s.initialized,
      v => v && this._syncData(),
      { immediate: true }
    );
  },

  methods: {
    async save() {
      if (!this.validate()) return;

      const diff = this._diff();

      if (_.isEmpty(diff)) {
        this._handleSaved();

        return;
      }

      try {
        this.loading = true;

        await this.$store.dispatch("updateProfile", diff);

        this._syncData();
        this._handleSaved();
      } catch (err) {
        this._handleError(err);
      } finally {
        this.loading = false;
      }
    },

    _syncData() {
      const v = this.$store.state;
      const data = {};

      this.fields.forEach(f => {
        if (f.component === "header") return;

        let value;

        if (typeof f.getter === "function") {
          value = f.getter.apply(v);
        } else {
          value = _.get(v, f.id);
        }

        if (typeof f.setter === "function") {
          f.setter.call(data, value);
        } else {
          setPath(data, f.id, value);
        }
      });

      this._initialData = JSON.parse(JSON.stringify(data));
      this.data = data;
    },

    _handleSaved() {
      this.flash("Сохранено!");
    },

    _diff() {
      return difference(this.data, this._initialData);
    },

    _handleLeave(next) {
      if (!_.isEmpty(this._diff())) {
        this._next = next;
        this.closeConfirmation = true;
      } else {
        next();
      }
    },
  },
};
</script>

<template>
  <v-skeleton-loader :loading="!$store.state.initialized" type="card">
    <v-form ref="form">
      <FormFields :fields="filteredFields" :data="data" :errors="errors" :title="title" @update="clearFieldErrors">
        <v-card-text v-if="hint" class="pt-4">{{ hint }}</v-card-text>

        <template v-slot:footer>
          <v-card-actions>
            <v-btn :loading="loading" color="primary" @click="save">
              Сохранить
            </v-btn>
          </v-card-actions>
        </template>
      </FormFields>
    </v-form>

    <FlashMessage v-model="message" :color="messageColor" />

    <ConfirmationDialog v-model="closeConfirmation" @confirm="_next()" @cancel="_next(false)">
      Вы вносили изменения в форму, действительно хотите закрыть?
    </ConfirmationDialog>
  </v-skeleton-loader>
</template>
