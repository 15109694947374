<script>
export default {
  name: "FlashMessage",
  inheritAttrs: false,
  props: ["value", "color"],
};
</script>

<template>
  <v-snackbar :value="value" :color="color" :timeout="6000" bottom>
    {{ value }}
    <v-btn text dark @click="$emit('input', null)">Закрыть</v-btn>
  </v-snackbar>
</template>
