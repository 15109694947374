<script>
import FormInput from "../../mixins/FormInput";

export default {
  name: "StringInput",
  extends: FormInput,
  inheritAttrs: false,
  props: ["max", "inputType", "suffix"],

  methods: {
    _updateValue(value) {
      this.$emit("input", this._castValue(value));
    },

    _castValue(value) {
      switch (this.inputType) {
        case "number":
          return /^\d+$/.test(value) ? parseInt(value) : value;

        default:
          return value;
      }
    },
  },
};
</script>

<template>
  <v-text-field
    v-bind="inputOptions"
    :value="value"
    :counter="max"
    :suffix="suffix"
    :type="inputType"
    :hide-details="max ? false : 'auto'"
    @input="_updateValue"
  />
</template>
