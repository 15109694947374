<script>
export default {
  name: "StringField",
  inheritAttrs: false,
  props: ["value", "suffix"],
};
</script>

<template functional>
  <span v-if="props.value">{{ props.value }} {{ props.suffix }}</span>
  <span v-else>&mdash;</span>
</template>
