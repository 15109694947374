import rules from '@/core/rules';

export default {
  title: 'Разделы призов',
  singularTitle: 'Раздел призов',
  name: 'name',
  icon: 'mdi-grid',
  permissions: {
    create: true,
    update: true,
    delete: true,
  },

  fields: [
    {
      id: 'id',
      label: 'ID',
      component: 'primary',
    },

    {
      id: 'name',
      label: 'Название',
      rules: [ rules.required ],
      max: 255,
    },

    {
      id: 'sort',
      label: 'Порядок отображения',
      hint: 'Чем больше значение, тем ниже будет отображаться',
      rules: [ rules.required, rules.integer ],
      default: 0,
    },

    { id: "is_active", label: "Активно", component: "boolean", default: true },

    { label: "Дата создания", id: "created_at", component: "date", disable: true },
    { label: "Дата обновления", id: "updated_at", component: "date", disable: true },
  ],

  relations: [
    {
      id: "gallery",
      label: "Галерея",
      resourceName: "galleryImages",
      foreignKey: "parent_id",
      morphKeyName: "parent_type",
      morphKey: "catalogCategory",
      relation: "gallery",
    },

    {
      id: 'products',
      resourceName: 'prize',
      foreignKey: 'catalog_category_id',
      manyToMany: true,
      permissions: {
        create: false,
        update: false,
      },
    },
  ]
}