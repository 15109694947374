import { disputeSource, disputeState, receiptState } from "./enums";
import rules from '@/core/rules';
import { isManualReceiptService } from '@/resources/settings/utils';
import { allowWhenPending } from '@/resources/receipts';

const isNewFromManager = function() {
  return this.source === "manager" && this.state === "new";
};

export default {
  title: "Диспуты",
  singularTitle: "Диспут",
  icon: "mdi-chat-alert",
  sortBy: "id",
  sortDesc: true,
  permissions: {
    create: true,
    delete: isNewFromManager,
    update: isNewFromManager,
  },

  rowClass() {
    return this.state === "new" ? "yellow lighten-4" : null;
  },

  fields: [
    {
      id: "id",
      label: "ID",
      component: "primary",
    },

    {
      id: "receipt_id",
      label: "Фото",
      component: "belongs-to",
      relation: "receipt",
      resourceName: "receipts",
      disableForUpdate: true,
      sortable: false,
    },

    {
      id: "client_id",
      label: "Участник",
      component: "belongs-to",
      relation: "receipt.client",
      resourceName: "clients",
      disable: true,
      sortable: false,
    },

    {
      id: "source",
      label: "Источник",
      component: "enum",
      options: disputeSource,
      disable: true,
      sortable: false,
    },

    {
      id: "client_comment",
      label: "Комментарий пользователя",
      component: "text-input",
      disableForUpdate: function() {
        return this.source === "client";
      },
      sortable: false,
    },

    {
      id: "state",
      label: "Состояние",
      component: "enum",
      options: disputeState,
      disableForCreate: true,
      disableForUpdate: function() {
        return this.source === "manager";
      },
    },

    {
      id: "manager_comment",
      label: "Комментарий менеджера",
      component: "text-input",
      hint: "Будет направлено в уведомлении участнику",
      disableForCreate: true,
      sortable: false,
    },

    {
      id: "send_to_external_system",
      label: "Отправить во внешнюю систему",
      component: "boolean",
      disableForUpdate: true,
      hide: true,
      default: true,
      disableForCreate: isManualReceiptService,
    },

    {
      id: "external_system_comment",
      label: "Текст обращения",
      component: "text-input",
      disableForCreate: function() {
        return !this.send_to_external_system || isManualReceiptService();
      },
      disableForUpdate: true,
      hide: true,
      rules: [rules.required],
      hint: "Содержимое данного поля будет направлено во внешнюю систему",
    },

    {
      label: "Дата создания",
      id: "created_at",
      component: "date",
      disable: true,
    },

    // RECEIPT DATA
    {
      id: "receipt",
      label: "Данные фото",
      component: "header",
      disable: true,
    },

    { label: "Номер фото", id: "receipt.n", disable: true, sortable: false },

    {
      id: "receipt.image_url",
      label: "Фото фото",
      component: "photo",
      hideFromIndex: true,
      disable: true,
      sortable: false,
    },

    {
      id: "receipt.state",
      label: "Состояние фото",
      component: "enum",
      options: receiptState,
      sortable: false,
      disable: true,
    },

    {
      label: "Текущий кэшбэк",
      id: "receipt.client_cash_back",
      component: "numeric",
      disable: true,
      sortable: false,
    },

    { label: "Изменение кэшбэка", id: "cash_back_change", component: "numeric", disable: true },

    {
      id: "receipt.external_system",
      label: "Внешняя система",
      hideFromIndex: true,
      disable: true,
      sortable: false,
    },

    {
      id: "receipt.external_id",
      label: "ID внешней системы",
      hideFromIndex: true,
      disable: true,
      sortable: false,
    },
  ],

  relations: [
    {
      id: "items",
      label: "Позиции",
      resourceName: "receiptItems",
      parentResource: "receipts",
      parentResolver: "receipt",
      relation: "receipt.items",
      nested: true,
      permissions: {
        create: allowWhenPending,
        update: allowWhenPending,
        delete: allowWhenPending,
      },
    },

    {
      id: "transactions",
      label: "Транзакции",
      resourceName: "transactions",
      foreignKey: "receipt_id",
      localKey: "receipt_id",
      relation: "transactions",
      create: false,
    },
  ],

  filters: [
    {
      id: "state",
      label: "Состояние",
      component: "enum",
      options: disputeState,
      multiple: true,
      clearable: true,
    },

    {
      id: "source",
      label: "Источник",
      component: "enum",
      options: disputeSource,
      clearable: true,
    },
  ],

  actions: [
    {
      id: "recalc",
      title: "Пересчитать кэшбэк",
      hide() { return !this.permissions?.recalc; },
      async handler(api) {
        await api.post(`disputes/${this.id}/recalc`);
      },
    },

    {
      id: "sendToExternalSystem",
      title: "Отправить во внешнюю систему",
      fields: [
        {
          id: "content",
          label: "Текст обращения",
          component: "text-input",
          default: function() {
            return this.client_comment;
          },
        },
      ],

      hide: function() {
        return this.state !== "new" || !this.receipt.external_system;
      },

      handler: async function(api, params) {
        await api.post(`disputes/${this.id}/sendToExternalSystem`, params);

        return "Запрос отправлен!";
      },
    },

    {
      id: "reject",
      title: "Отклонить диспут",
      fields: [
        {
          id: "manager_comment",
          label: "Комментарий менеджера",
          component: "text-input",
          hint: "Будет направлено в уведомлении участнику",
        },
      ],

      hide() {
        return this.state !== "new" || this.source === "manager";
      },

      async handler(api, params) {
        await api.patch(`disputes/${this.id}`, {
          state: "declined",
          manager_comment: params.manager_comment || "",
        });
      },
    },
  ],
};
