<script>
import BaseBelongsTo from "./BaseBelongsTo";

export default {
  name: "BelongsToField",
  extends: BaseBelongsTo,
  props: {
    resourceName: {
      type: String,
      required: true,
    },
  },
};
</script>
