import resources from '@/resources';

export default {
  data: () => ({
    parent: null,
  }),

  methods: {
    _dataXhr(options) {
      return Promise.all([
        this.$api.get(this._dataUrl(options), this._cancelToken.token),
        this.$api.get(this._parentUrl(options), this._cancelToken.token),
      ])
    },

    _updateData(resp) {
      this.initialData = JSON.parse(JSON.stringify((resp[0] || resp).data));
      this.data = (resp[0] || resp).data;

      resp[1] && (this.parent = Object.freeze(resp[1].data));
    },

    _dataUrl(options) {
      return this._resolveRelationConfig(options).url(options?.parentKey || this.parentKey, options?.key || this.key);
    },

    _resolveRelationConfig(options) {
      return options ? resources[options.resource].relationById(options.relation) : this.relationConfig;
    },

    _parentUrl(options) {
      return this._resolveRelationConfig(options).parentResource().url(options?.parentKey || this.parentKey);
    },
  }
}