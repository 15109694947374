<script>
export default {
  name: "ImageField",
  inheritAttrs: false,
  props: ["value"],
};
</script>

<template>
  <v-img v-if="value" :src="value" max-height="50" max-width="50" contain />
  <span v-else>&mdash;</span>
</template>
