<script>
import resources from "@/resources";
import { value } from "@/utils";
import ResourceForm from "@/mixins/ResourceForm";
import FormFields from "@/components/FormFields";
import ConfirmationDialog from "@/components/ConfirmationDialog";
import FlashMessage from "@/components/FlashMessage";
import fields from "@/components/form";

export default {
  name: "ResourceCreate",
  components: { ConfirmationDialog, FlashMessage, FormFields },
  extends: ResourceForm,

  data: () => ({
    data: {},
    disabledFields: {},
    loading: false,
    clickedButton: null,
  }),

  computed: {
    resourceName() {
      return this.$route.params.resource;
    },

    resource() {
      return (this.resourceName && resources[this.resourceName]) || {};
    },

    fields() {
      return this.resource.fields.filter((field) => {
        return (
          value(field.disable, this.data) !== true &&
          value(field.disableForCreate, this.data) !== true &&
          (fields[field.component || "string"] !== undefined || field.component === "header") &&
            !this._filterField(field)
        );
      });
    },

    breadcrumbs() {
      return [
        {
          text: this.resource.title,
          to: this.resource.allows('viewAny') && this.resource.path(),
          exact: true,
        },

        { text: "Создание" },
      ];
    },
  },

  watch: {
    resourceName: {
      immediate: true,
      handler: function() {
        this._initData();
      },
    },
  },

  methods: {
    async create(ev, repeat) {
      if (!this.validate()) return;

      this.loading = true;
      this.message = null;
      this.clickedButton = repeat ? "repeat" : "create";

      try {
        const { data } = await this._xhr();

        this._initData();
        this.$refs.form.resetValidation();

        await this.$store.dispatch("pushResourceItem", { resourceName: this.resourceName, data });

        if (!repeat) {
          await this.$router.replace(this._redirectUrl(data));
        }
      } catch (err) {
        this._handleError(err);
      } finally {
        this.loading = false;
      }
    },

    _xhr() {
      return this.$api.post(this.resource.url(), this.data);
    },

    createAndRepeat(ev) {
      this.create(ev, true);
    },

    _initData() {
      const data = {};
      this.disabledFields = {};

      this.fields.forEach((field) => {
        let value;
        const fromQuery = this.$route.query[field.id];

        if (fromQuery) {
          this.disabledFields[field.id] = true;
        }

        if ((value = fromQuery) || (value = field.default) !== undefined) {
          data[field.id] = value;
        }
      });

      this.initialData = { ...data };
      this.data = data;
    },

    _filterField () {
      return false;
    },

    _redirectUrl(data) {
      return this.resource.path(data);
    },
  },
};
</script>

<template>
  <v-skeleton-loader :loading="!$store.state.initialized" type="card">
    <v-breadcrumbs :items="breadcrumbs" class="pl-0" large />

    <v-form ref="form">
      <FormFields
        v-if="data"
        :title="`${resource.singularTitle}: создание`"
        :fields="fields"
        :data="data"
        :errors="errors"
        :disabled-fields="disabledFields"
        @update="clearFieldErrors"
      >
        <template v-slot:footer>
          <v-card-actions>
            <v-btn :disabled="loading" :loading="loading && clickedButton === 'create'" color="primary" @click="create">
              Создать
            </v-btn>

            <v-btn
              :disabled="loading"
              :loading="loading && clickedButton === 'repeat'"
              color="accent"
              @click="createAndRepeat"
            >
              <v-icon class="d-sm-none">save</v-icon>
              <span class="d-none d-sm-inline">Создать и создать еще</span>
            </v-btn>

            <v-btn :disabled="loading" text @click="$router.back()">
              <v-icon class="d-sm-none">close</v-icon>
              <span class="d-none d-sm-inline">Закрыть</span>
            </v-btn>
          </v-card-actions>
        </template>
      </FormFields>
    </v-form>

    <FlashMessage v-model="message" :color="messageColor" />

    <ConfirmationDialog
      v-model="closeConfirmation"
      message="Вы действительно хотите отменить все изменения?"
      @confirm="_next()"
      @cancel="_next(false)"
    />
  </v-skeleton-loader>
</template>
