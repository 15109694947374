<script>
export default {
  name: "EnumField",
  inheritAttrs: false,
  props: ["value", "options", "colors"],
  computed: {
    option() {
      return this.options.find(opt => opt.value === this.value);
    },

    text() {
      return (this.option && this.option.text) || this.value;
    },

    color() {
      return this.option && this.option.color;
    },
  },
};
</script>

<template>
  <span v-if="!value">&mdash;</span>
  <v-chip v-else :color="color" small class="text-no-wrap">{{ text }}</v-chip>
</template>
