import resources from '@/resources';
import _ from 'lodash';
import { value } from '@/utils';

const DEFAULT_PERMISSIONS = {
  view: true,
  viewAny: true,
  update: true,
  create: true,
  delete: true,
};

export class Relation {
  constructor (parentResource, config) {
    this.id = config.id;
    this._parentResource = parentResource;
    this.parentResourceName = config.parentResource;
    this.resourceName = config.resourceName;
    this.parentResolver = config.parentResolver;
    this.label = config.label;
    this.foreignKey = config.foreignKey;
    this.relation = config.relation;
    this.nested = config.nested || null;
    this.permissions = _.extend({}, DEFAULT_PERMISSIONS, config.permissions || {
      create: config.create === undefined ? true : config.create
    });
    this.morphKeyName = config.morphKeyName;
    this.morphKey = config.morphKey;
    this.localKey = config.localKey;
    this.baseUrl = config.url;
  }

  resource() {
    return resources[this.resourceName];
  }

  parentResource() {
    return this.parentResourceName ? resources[this.parentResourceName] : this._parentResource;
  }

  resolveParent(data) {
    if (!this.parentResolver) return data;

    return typeof this.parentResolver === "function" ? value(this.parentResolver, data) : data[this.parentResolver];
  }

  path(parent, related = null) {
    const basePath = `${this.parentResource().path(parent)}/${this.id}`;

    return related ? `${basePath}/${this.resource().key(related)}` : basePath;
  }

  url(parent, related = null) {
    if (!this.nested) return this.resource().url(related);

    const basePath = typeof this.baseUrl === 'function'
      ? value(this.baseUrl, parent, this)
      : `${this.parentResource().url(parent)}/${this.id}`;

    return related ? `${basePath}/${this.resource().key(related)}` : basePath;
  }

  allows(action, parent, related = null) {
    return value(this.permissions[action], parent) && this.resource().allows(action, related);
  }

  resolveForeignKey(parent) {
    return this.localKey ? parent[this.localKey] : this.parentResource().key(parent);
  }

  applyFilters(parent, data) {
    if (this.foreignKey && parent) {
      data[this.foreignKey] = this.resolveForeignKey(parent);
    }

    if (this.morphKeyName && this.morphKey) {
      data[this.morphKeyName] = this.morphKey;
    }
  }
}