<script>
export default {
  name: "FieldWrap",
  inheritAttrs: false,
  props: ["label", "hint"],
};
</script>

<template>
  <div class="field-wrap">
    <v-row>
      <v-col cols="12" sm="4" md="3" xl="2" class="pb-0 pb-sm-3">
        <div class="font-weight-medium">{{ label }}</div>
      </v-col>

      <v-col cols="12" sm="8" md="5" lg="4" class="pt-1 pt-sm-3">
        <slot />
      </v-col>

      <v-col v-if="hint" cols="12" sm="8" md="4" offset-sm="4" offset-md="0">
        <div class="caption">{{ hint }}</div>
      </v-col>
    </v-row>
  </div>
</template>
