<script>
import _ from "lodash";
import FieldList from "../components/FieldList";
import ResourcePage from "../mixins/ResourcePage";
import fields from "../components/details";
import { value } from '@/utils';
import ResourceList from "../components/ResourceList";
import ConfirmationDialog from "../components/ConfirmationDialog";
import FlashMessage from "../components/FlashMessage";
import ResourceActionsMenu from "../components/ResourceActionsMenu";
import ResourceActionsDialog from "../components/ResourceActionDialog";

export default {
  name: "ResourceShow",
  extends: ResourcePage,
  components: {
    ResourceActionsDialog,
    ResourceActionsMenu,
    FlashMessage,
    ConfirmationDialog,
    ResourceList,
    FieldList,
  },

  data: () => ({
    confirmDelete: false,
    showingAction: false,
    currentAction: null,
  }),

  computed: {
    fields() {
      return this.resource.fields.filter(field => {
        return (
          value(field.hide, this.data) !== true &&
          value(field.hideFromDetails, this.data) !== true &&
          (fields[field.component || "string"] !== undefined || field.component === "header") &&
          !this._filterField(field)
        );
      });
    },

    relations () {
      return this.resource.relations.filter(rel => value(rel.hide, this.data) !== true);
    },

    breadcrumbs() {
      return [
        {
          text: this.resource.title,
          to: this.resource.path(),
          exact: true,
        },

        {
          text: this.resource.name(this.data),
          to: this.resource.path(this.data),
          exact: true,
        },
      ];
    },
  },

  methods: {
    _updateData(resp) {
      this.data = Object.freeze(resp.data);
    },

    async _trash() {
      try {
        this.loading = true;

        await this.$api.delete(this.resource.url(this.key));

        await this.$router.replace(this.resource.path());
      } catch (err) {
        this.flash("Не удалось удалить запись", "error");
      } finally {
        this.loading = false;
      }
    },

    showAction(action) {
      this.currentAction = action;
      this.showingAction = true;
    },

    _relationItems(rel) {
      return rel.relation && _.get(this.data, rel.relation);
    },

    _filterField () {
      return false;
    },

    _reloadPage () {
      this.load();
      this.$refs.relations.forEach(r => r.load());
    },
  },
};
</script>

<template>
  <v-skeleton-loader :loading="!$store.state.initialized" type="card">
    <v-breadcrumbs :items="breadcrumbs" class="pl-0" large />

    <FieldList :fields="fields" :title="resource.singularTitle" :data="data" :loading="loading">
      <template v-slot:title>
        <v-btn v-if="resource.allows('update', data)" :to="`${resource.path(data)}/edit`" color="accent" class="mr-3">
          <v-icon>mdi-pencil</v-icon>
        </v-btn>

        <v-btn v-if="resource.allows('delete', data)" color="error" class="mr-3" @click="confirmDelete = true">
          <v-icon>mdi-delete</v-icon>
        </v-btn>

        <ResourceActionsMenu :resource-name="resourceName" :data="data" @choose="showAction($event)">
          <template v-slot="{ on }">
            <v-btn class="mr-3" v-on="on">
              <v-icon>mdi-dots-vertical</v-icon>
            </v-btn>
          </template>
        </ResourceActionsMenu>

        <v-btn @click="load()">
          <v-icon>mdi-refresh</v-icon>
        </v-btn>
      </template>

      <template v-slot:footer>
        <v-card-actions>
          <v-btn :disabled="loading" text @click="$router.back()">
            Закрыть
          </v-btn>
        </v-card-actions>
      </template>
    </FieldList>

    <template v-if="loaded">
      <ResourceList
        v-for="rel in relations"
        :key="rel.id"
        :resource-name="rel.resourceName"
        :relation-config="rel"
        :parent="rel.resolveParent(data)"
        :label="rel.label || rel.resource().label"
        :initial-items="_relationItems(rel)"
        :per-page="5"
        class="mt-9"
        ref="relations"
      />
    </template>

    <ConfirmationDialog v-model="confirmDelete" @confirm="_trash">
      Вы уверены, что хотите удалить эту запись?
    </ConfirmationDialog>

    <FlashMessage v-model="message" :color="messageColor" />

    <ResourceActionsDialog
      v-model="showingAction"
      :resource-name="resourceName"
      :data="data"
      :action="currentAction"
      @run="_reloadPage"
    />
  </v-skeleton-loader>
</template>
