<script>
export default {
  name: "ComputedField",
  inheritAttrs: false,
  props: ["value", "html"],
};
</script>

<template>
  <span v-if="html" v-html="value" />
  <span v-else>{{ value }}</span>
</template>
