<template>
  <v-app id="inspire">
    <v-navigation-drawer v-model="drawer" app>
      <v-list dense>
        <v-list-item-group>
          <v-list-item v-if="$store.state.initialized" to="/" link>
            <v-list-item-action>
              <v-icon>mdi-view-dashboard</v-icon>
            </v-list-item-action>

            <v-list-item-content>
              <v-list-item-title>Дашборд</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-skeleton-loader v-else type="list-item-avatar" />
        </v-list-item-group>

        <v-divider />
        <v-subheader v-if="$store.state.initialized" class="text-uppercase">Активность</v-subheader>
        <v-skeleton-loader v-else type="card-heading" />

        <v-list-item-group>
          <MenuResourceItem resource-name="clients" />
          <MenuResourceItem resource-name="receipts" />
          <MenuResourceItem resource-name="disputes" />
          <MenuResourceItem resource-name="orders" />
          <MenuResourceItem resource-name="partners" />
          <MenuResourceItem resource-name="coupons" />
          <MenuResourceItem resource-name="transactions" />
        </v-list-item-group>

        <v-divider />

        <v-subheader v-if="$store.state.initialized" class="text-uppercase">Настройки</v-subheader>
        <v-skeleton-loader v-else type="card-heading" />

        <v-list-item-group>
          <MenuResourceItem resource-name="promotions" />
          <MenuResourceItem resource-name="products" />
          <MenuResourceItem resource-name="catalogCategories" />
          <MenuResourceItem resource-name="prize" />
          <MenuResourceItem resource-name="couponSeries" />
        </v-list-item-group>

        <v-divider />
        <v-subheader v-if="$store.state.initialized" class="text-uppercase">Разное</v-subheader>
        <v-skeleton-loader v-else type="card-heading" />

        <SettingsMenu v-if="$store.state.initialized" />
        <v-skeleton-loader v-else type="list-item-avatar" />
      </v-list>
    </v-navigation-drawer>

    <v-app-bar color="primary" app dark>
      <v-app-bar-nav-icon @click.stop="drawer = !drawer" />

      <v-toolbar-title v-if="$store.state.initialized">{{ $store.state.settings.companyName }}</v-toolbar-title>
      <v-skeleton-loader v-else type="heading" />

      <v-spacer />

      <v-menu v-if="$store.state.initialized">
        <template v-slot:activator="{ on }">
          <v-avatar v-on="on">
            <v-img :src="`https://i.pravatar.cc/150?u=${$store.state.user.email}`" />
          </v-avatar>
        </template>

        <v-list dense bottom left>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>
                {{ $store.state.user.name }}
              </v-list-item-title>

              <v-list-item-subtitle>
                {{ $store.state.user.email }}
              </v-list-item-subtitle>
            </v-list-item-content>

            <v-list-item-action>
              <v-avatar>
                <v-img :src="`https://i.pravatar.cc/150?u=${$store.state.user.email}`" />
              </v-avatar>
            </v-list-item-action>
          </v-list-item>

          <v-divider />

          <v-list-item to="/profile" link>
            <v-list-item-content>
              <v-list-item-title>Профиль</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item to="/logout" link>
            <v-list-item-content>
              <v-list-item-title>Выйти</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-menu>
      <v-skeleton-loader v-else type="avatar" />
    </v-app-bar>

    <v-main>
      <v-container fluid>
        <router-view />
      </v-container>
    </v-main>

    <v-footer app>
      <span>&copy; 2021 BotMarketing</span>
    </v-footer>
  </v-app>
</template>

<script>
import MenuResourceItem from "./components/MenuResourceItem";
import SettingsMenu from "./components/SettingsMenu";

export default {
  components: { SettingsMenu, MenuResourceItem },
  props: {
    source: String,
  },

  data: () => ({
    drawer: null,
  }),

  computed: {
    canShowView() {
      return !this.$route.matched.some(m => m.meta.requiresAuth) || this.$store.state.initialized;
    },
  },

  created() {
    if (this.$auth.isAuthenticated()) {
      this.$api.setToken(this.$auth.getToken());
      this.$store.dispatch("initialize");
    }
  },
};
</script>
