<script>
import tabs from "../resources/settings";

export default {
  name: "SettingsMenu",

  created() {
    this.tabs = tabs;
  },
};
</script>

<template>
  <v-list-group prepend-icon="mdi-settings">
    <template v-slot:activator>
      <v-list-item-icon>
        <v-icon>mdi-settings</v-icon>
      </v-list-item-icon>
      <v-list-item-title>Настройки</v-list-item-title>
    </template>

    <v-list-item v-for="tab in tabs" :key="tab.id" :to="`/settings/${tab.id}`" link>
      <v-list-item-content>
        <v-list-item-title>{{ tab.title }}</v-list-item-title>
      </v-list-item-content>
    </v-list-item>
  </v-list-group>
</template>
