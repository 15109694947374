import { Resource } from "@/core/resource";
import promotions from "./promotions";
import products from "./products";
import receipts from "./receipts";
import clients from "./clients";
import coupons from "./coupons";
import productPatterns from "./productPatterns";
import transactions from "./transactions";
import receiptItems from "./receiptItems";
import partners from "./partners";
import partnerPhones from "./partnerPhones";
import couponSeries from "./couponSeries";
import disputes from "./disputes";
import regions from "./regions";
import galleryImage from './galleryImages';
import catalogCategories from './catalogCategories';
import orders from './orders';
import orderItems from './orderItems';
import prize from './prize';

export default Object.freeze({
  promotions: Object.freeze(new Resource("promotions", promotions)),
  products: Object.freeze(new Resource("products", products)),
  prize: Object.freeze(new Resource("prize", prize)),
  receipts: Object.freeze(new Resource("receipts", receipts)),
  clients: Object.freeze(new Resource("clients", clients)),
  coupons: Object.freeze(new Resource("coupons", coupons)),
  productPatterns: Object.freeze(new Resource("productPatterns", productPatterns)),
  transactions: Object.freeze(new Resource("transactions", transactions)),
  receiptItems: Object.freeze(new Resource("receiptItems", receiptItems)),
  partners: Object.freeze(new Resource("partners", partners)),
  partnerPhones: Object.freeze(new Resource("partnerPhones", partnerPhones)),
  couponSeries: Object.freeze(new Resource("couponSeries", couponSeries)),
  disputes: Object.freeze(new Resource("disputes", disputes)),
  regions: Object.freeze(new Resource("regions", regions)),
  galleryImages: Object.freeze(new Resource("galleryImages", galleryImage)),
  catalogCategories: Object.freeze(new Resource("catalogCategories", catalogCategories)),
  orders: Object.freeze(new Resource("orders", orders)),
  orderItems: Object.freeze(new Resource("orderItems", orderItems)),
});
