import { HttpException } from '@/plugins/api';
import FlashesMessages from "./FlashesMessages";

export default {
  mixins: [FlashesMessages],

  data: () => ({
    closeConfirmation: false,
    errors: {},
  }),

  methods: {
    clearFieldErrors(field) {
      delete this.errors[field.id];
    },

    validate() {
      if (!this.$refs.form.validate()) {
        this.flash("Форма содержит ошибки", "warning");

        return false;
      } else {
        return true;
      }
    },

    _handleError(err) {
      if (err instanceof HttpException) {
        if (err.code === 422) {
          this.errors = err.data.errors;
          this.flash("Форма содержит ошибки", "warning");
        } else {
          this.flash(err.data.message || "Неизвестная ошибка", "error");
        }
      } else {
        //console.error(err);
        this.flash("Неизвестная ошибка", "error");
      }
    },
  },
};
