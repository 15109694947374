import { orderState } from '@/resources/enums';
import moment from 'moment';

export default {
  title: "Заказы",
  singularTitle: "Заказ",
  sortBy: "id",
  sortDesc: true,
  icon: "mdi-shopping",
  permissions: {
    update: function () {
      return ["new", "sent"].indexOf(this.state) >= 0;
    },
  },

  name () {
    if (!this.id) return;

    const date = moment(this.created_at).format('DD.MM.YYYY');

    return `Заказ №${this.id} от ${date}`;
  },

  fields: [
    { id: "id", label: "ID", component: "primary" },

    {
      id: "client_id",
      label: "Клиент",
      component: "belongs-to",
      resourceName: "clients",
      relation: "client",
      sortable: false,
      disable: true,
    },

    {
      id: "first_name",
      label: "Имя",
      sortable: false,
      disable: true,
    },

    {
      id: "last_name",
      label: "Фамилия",
      sortable: false,
      disable: true,
    },

    {
      id: "phone",
      label: "Телефон",
      sortable: false,
      disable: true,
    },

    {
      id: "address_string",
      label: "Адрес",
      sortable: false,
      disable: true,
    },

    {
      id: "comment",
      label: "Комментарий",
      sortable: false,
    },

    {
      id: "state",
      label: "Состояние",
      component: "enum",
      options: orderState,
      sortable: false,
    },

    {
      id: "created_at",
      label: "Дата создания",
      component: "datetime",
      disable: true,
    },
  ],

  relations: [
    {
      id: "items",
      label: "Позиции",
      resourceName: "orderItems",
      relation: "items",
    }
  ]
}