<script>
import FormInput from "../../mixins/FormInput";

export default {
  name: "TextareaInput",
  extends: FormInput,
  props: ["max"],
};
</script>

<template>
  <v-textarea
    v-bind="inputOptions"
    :counter="max"
    :value="value"
    :rows="1"
    auto-grow
    @input="$emit('input', $event)"
  />
</template>
