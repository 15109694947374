<script>
import ResourceCreate from '@/views/ResourceCreate.vue';
import ResourceRelatedForm from '@/mixins/ResourceRelatedForm';
import ResourcePage from '@/mixins/ResourcePage';
import ResourceRelatedPage from '@/mixins/ResourceRelatedPage';

export default {
  name: "ResourceRelatedCreate",
  extends: ResourceCreate,
  mixins: [ ResourcePage, ResourceRelatedPage, ResourceRelatedForm ],

  computed: {
    breadcrumbs() {
      return [
        {
          text: this.parentResource.title,
          to: this.parentResource.allows('viewAny') && this.parentResource.path() || undefined,
          exact: true,
        },

        {
          text: this.parentResource.name(this.parent),
          to: this.parentResource.allows('view') && this.parentResource.path(this.parentKey) || undefined,
          exact: true,
        },

        {
          text: this.relationConfig.label || this.resource.title,
        },

        { text: "Создание" },
      ];
    },
  },

  methods: {
    _dataXhr(options) {
      return this.$api.get(this._parentUrl(options), this._cancelToken.token);
    },

    _updateData(resp) {
      this.parent = Object.freeze(resp.data);
    },

    _xhr() {
      return this.$api.post(this._url(), { ...this.data, ...this._relatedData() });
    },

    _relatedData() {
      if (this.relationConfig.nested) return {};

      const data = {
        [this.relationConfig.foreignKey]: this.parentKey,
      };

      if (this.relationConfig.morphKeyName) {
        data[this.relationConfig.morphKeyName] = this.relationConfig.morphKey;
      }

      return data;
    },

    _redirectUrl(data) {
      return this.relationConfig.allows('view', this.parent, data)
        ? this.relationConfig.path(this.parent, data)
        : this.parentResource.path(this.parent);
    },
  },
}
</script>