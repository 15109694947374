<script>
import _ from "lodash";
import { DownloadFile } from '@/core/actionResults';
import { value } from '@/utils';
import ResourceForm from "../mixins/ResourceForm";
import fields from "../components/form";

export default {
  name: "ResourceActionsDialog",
  extends: ResourceForm,
  components: fields,

  props: {
    value: null,

    resourceName: {
      type: String,
      required: true,
    },

    data: Object,
    action: Object,
  },

  data: () => ({
    loading: false,
    hasRun: false,
    params: null,
  }),

  computed: {
    fields() {
      return (this.action.fields || []).filter(a => !value(a.disable, this.data, this.params));
    },
  },

  watch: {
    value(v) {
      v && this.reset();
    },

    action() {
      this.reset();
    },
  },

  methods: {
    async run() {
      if (this.loading || !this.validate()) return;

      try {
        this.loading = true;
        this.message = null;

        const result = await this.action.handler.call(this.data, this.$api, this.params);

        if (_.isString(result)) {
          this.flash(result);
        } else if (result instanceof DownloadFile) {
          result.save();
          this.flash("Действие успешно выполнено! Файл скачивается.");
        } else {
          this.flash("Действие успешно выполнено!");
        }

        this.hasRun = true;

        this.$emit("run");
      } catch (err) {
        this._handleError(err);
      } finally {
        this.loading = false;
      }
    },

    reset() {
      this.hasRun = false;
      this.message = null;
      this.params = this._defaultParams();
    },

    _defaultParams() {
      const res = {};

      (this.action.fields||[]).forEach(f => (res[f.id] = value(f.default, this.data)));

      return res;
    },
  },
};
</script>

<template>
  <v-dialog :value="value" max-width="450" @input="$emit('input', $event)">
    <v-card v-if="action" :loading="loading">
      <v-card-title>{{ action.title }}</v-card-title>

      <v-card-text>
        <v-alert v-if="message" :color="messageColor" text>
          {{ message }}
        </v-alert>

        <template v-if="!hasRun">
          Вы действительно хотите запустить это действие?
        </template>
      </v-card-text>

      <v-form v-if="!hasRun" ref="form" @submit.prevent="run">
        <v-list>
          <v-list-item v-for="field in fields" :key="field.id" class="filter-field">
            <v-list-item-content>
              <v-list-item-title>{{ field.label }}</v-list-item-title>

              <component
                v-bind="field"
                v-model="params[field.id]"
                :is="field.component || 'string'"
                :data="params"
                :errors="errors[field.id]"
                @input="clearFieldErrors(field)"
              />

              <span v-if="field.hint" class="caption">{{ field.hint }}</span>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-form>

      <v-card-actions>
        <v-spacer />
        <v-btn v-if="!hasRun" color="warning" @click="run">Подтвердить</v-btn>
        <v-btn @click="$emit('input', false)">
          {{ hasRun ? "Закрыть" : "Отмена" }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
