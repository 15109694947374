import rules from "../core/rules";
import payouts from "./settings/payouts";
import receipts from "./settings/receipts";
import coupons from "./settings/coupons";
import triggers from "./settings/triggers";
import referral from '@/resources/settings/referral';

export default [
  {
    id: "main",
    title: "Основное",
    fields: [
      {
        id: "settings.companyName",
        label: "Название компании",
        max: 128,
        rules: [rules.required],
      },

      {
        id: "settings.timezone",
        label: "Временная зона",
        rules: [rules.required],
      },
    ],
  },

  coupons,
  receipts,
  payouts,
  triggers,
  referral,
];
