<script>
import FormInput from "../../mixins/FormInput";

export default {
  name: "TimeInput",
  extends: FormInput,
  props: ["seconds"],

  data: () => ({
    show: false,
  }),

  computed: {
    listeners() {
      const self = this;

      return {
        input: function(v) {
          self.$emit("input", v);
        },

        [this.seconds ? "click:second" : "click:minute"]: function() {
          self.show = false;
        },
      };
    },
  },
};
</script>

<template>
  <v-menu
    v-model="show"
    :close-on-content-click="false"
    transition="scale-transition"
    offset-y
    max-width="290px"
    min-width="290px"
  >
    <template v-slot:activator="{ on }">
      <v-text-field v-bind="inputOptions" :value="value" :clearable="clearable" readonly v-on="on" />
    </template>

    <v-time-picker :value="value" :use-seconds="seconds" format="24hr" v-on="listeners" />
  </v-menu>
</template>
