import BooleanField from "../details/BooleanField";
import DateField from "../details/DateField";
import DateTimeField from "../details/DateTimeField";
import BelongsToField from "../details/BelongsToField";
import StringField from "../details/StringField";
import ImageField from "./ImageField";
import EnumField from "../details/EnumField";
import ComputedField from "../details/ComputedField";
import MorphToField from "../details/MorphToField";
import PrimaryField from "./PrimaryField";

export default {
  boolean: BooleanField,
  date: DateField,
  datetime: DateTimeField,
  "belongs-to": BelongsToField,
  photo: ImageField,
  string: StringField,
  enum: EnumField,
  computed: ComputedField,
  "morph-to": MorphToField,
  primary: PrimaryField,
  numeric: StringField,
  "text-input": StringField,
};
