<script>
export default {
  name: "CallbackPage",

  data: () => ({
    failed: false,
  }),

  async beforeMount() {
    try {
      const token = await this.$auth.handleResponse(this.$route.query);

      this.$api.setToken(token);

      this.$store.dispatch("initialize");

      this.$router.replace(this.$auth.getReturnUrl() || "/");
    } catch {
      this.failed = true;
    }
  },
};
</script>

<template>
  <div v-if="failed" class="display-1">Не удалось авторизоваться.</div>
  <div v-else class="display-1">Авторизация...</div>
</template>
