import _ from "lodash";
import { value } from "../utils";
import { Relation } from '@/core/relation';

const DEFAULT_PERMISSIONS = {
  view: true,
  viewAny: true,
  update: false,
  create: false,
};

export class Resource {
  constructor(id, config) {
    this.id = id;
    this.baseUrl = config.url || id;
    this.primaryKey = config.primaryKey || "id";
    this.title = config.title || id;
    this.singularTitle = config.singularTitle || this.title;
    this.sortBy = config.sortBy || this.primaryKey;
    this.sortDesc = config.sortDesc || false;
    this.perPage = config.perPage || 15;
    this.fields = config.fields || [];
    this.relations = (config.relations || []).map(cfg => new Relation(this, cfg));
    this.icon = config.icon;
    this.serverOptions = config.serverOptions || false;
    this.incrementing = config.incrementing !== undefined ? config.incrementing : true;
    this.permissions = _.extend({}, DEFAULT_PERMISSIONS, config.permissions || {});
    this.actions = config.actions || [];
    this.filters = config.filters || [];
    this.views = config.views || {};
    this.rowClass = config.rowClass || null;
    this.static = config.static || false;
    this.config = config;
  }

  name(model) {
    if (!model) return null;

    if (typeof this.config.name === "function") {
      return this.config.name.apply(model) || model.text;
    }

    return (
      (this.config.name ? model[this.config.name] : model.text) ||
      model.text ||
      this.key(model) ||
      ""
    ).toString();
  }

  key(model) {
    const value = _.isObject(model) ? model[this.primaryKey] || model.id : model;

    return (value && this.incrementing && parseInt(value)) || value;
  }

  path(model = null) {
    return (model && `/${this.id}/${this.key(model)}`) || `/${this.id}`;
  }

  url(model = null) {
    return (model && `/${this.baseUrl}/${this.key(model)}`) || `/${this.baseUrl}`;
  }

  allows(action, data) {
    return value(this.permissions[action], data);
  }

  filterDefaults(view) {
    const res = {};

    ((view ? this.views[view] : this).filters || [])
      .forEach(f => (res[f.id] = f.default === undefined ? null : f.default));

    return res;
  }

  relationById(id) {
    const result = this.relations.find(r => r.id === id);

    if (!result) throw new Error(`Relation ${id} is not defined for resource ${this.id}`);

    return result;
  }
}
