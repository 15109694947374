<script>
import ResourceShow from '@/views/ResourceShow.vue';
import ResourceRelatedPage from '@/mixins/ResourceRelatedPage';
import ResourceRelatedForm from '@/mixins/ResourceRelatedForm';

export default {
  name: 'ResourceRelatedShow',
  extends: ResourceShow,
  mixins: [ ResourceRelatedPage, ResourceRelatedForm ],

  computed: {
    breadcrumbs () {
      return [
        {
          text: this.parentResource.title,
          to: this.parentResource.allows('viewAny') && this.parentResource.path() || undefined,
          exact: true,
        },

        {
          text: this.parentResource.name(this.parent),
          to: this.parentResource.allows('view') && `${this.parentResource.path()}/${this.parentKey}` || undefined,
          exact: true,
        },

        {
          text: this.relationConfig.label || this.resource.title,
        },

        {
          text: this.resource.name(this.data),
          to: this.resource.path(this.data),
          exact: true,
        },
      ];
    },
  },
}
</script>