import rules from "../../core/rules";
import { orderState, receiptState } from '../enums';
import _ from "lodash";

export default {
  id: "triggers",
  title: "Интеграция с туннелями",
  fields: [
    {
      id: "user.web_hook_token",
      label: "Токен для веб хуков",
      hint: "Этот токен указывается в настройках решения",
      max: 255,
      rules: [rules.required],
    },

    {
      id: "settings.triggers",
      label: "Триггеры",
      hint: "Здесь можно настроить триггеры, которые будут вызываться при определенных событиях",
      component: "header",
    },

    {
      id: "settings.system.triggerBaseUrl",
      label: "Базовый путь для вызова триггеров",
    },

    {
      id: "settings.receipts.stateTriggerId",
      label: "Состояние чека: код триггера",
      hint: "Триггер будет вызываться при смене статуса чека",
    },

    {
      id: "settings.receipts.statesToNotify",
      label: "Состояние чека: состояния",
      hint: "Выберите состояния, по которым будет выполняться триггер",
      component: "enum",
      options: receiptState,
      multiple: true,

      getter() {
        return _.split(this.settings.receipts?.statesToNotify, ",");
      },

      setter(v) {
        this.settings.receipts.statesToNotify = _.join(v, ",");
      },
    },

    {
      id: "settings.payouts.stateTriggerId",
      label: "Состояние выплаты: код триггера",
      hint: "Триггер будет вызываться при смене состояния выплаты",
    },

    {
      id: "settings.disputes.stateTriggerId",
      label: "Состояние диспута: код триггера",
      hint: "Триггер будет вызываться при смене состояния диспута",
    },

    {
      id: "settings.coupons.stateTriggerId",
      label: "Состояние купона: код триггера",
      hint: "Триггер будет вызываться при смене состояния купона",
    },

    {
      id: "settings.orders.stateTriggerId",
      label: "Состояние заказа: код триггера",
      hint: "Триггер будет вызываться при смене статуса заказа",
    },

    {
      id: "settings.orders.statesToNotify",
      label: "Состояние заказа: состояния",
      hint: "Выберите состояния, по которым будет выполняться триггер",
      component: "enum",
      options: orderState,
      multiple: true,

      getter() {
        return _.split(this.settings.orders?.statesToNotify, ",");
      },

      setter(v) {
        this.settings.orders.statesToNotify = _.join(v, ",");
      },
    },

    {
      id: "settings.transactions.triggerId",
      label: "Транзакция создана: код триггера",
      hint: "Триггер будет вызываться при создании одной или нескольких транзакций",
    },
  ],
};
