import { targetType, transactionType } from "./enums";
import rules from "../core/rules";

export default {
  title: "Транзакции",
  singularTitle: "Транзакция",
  sortBy: "id",
  sortDesc: true,
  icon: "mdi-cash-multiple",
  permissions: {
    create: true,
  },

  fields: [
    { id: "id", label: "ID", component: "primary" },

    {
      id: "target_type",
      label: "Тип владельца",
      component: "enum",
      options: targetType,
      sortable: false,
      rules: [rules.required],
    },

    {
      id: "target_id",
      label: "Владелец",
      component: "morph-to",
      relation: "target",
      morphKeyName: "target_type",
      morphMap: {
        client: "clients",
        partner: "partners",
      },
      rules: [rules.required],
      sortable: false,
    },

    {
      id: "target.coupon_id",
      label: "Купон",
      component: "belongs-to",
      relation: "target.coupon",
      resourceName: "coupons",
      sortable: false,
      disable: true,
    },

    {
      id: "target.coupon.partner",
      label: "Партнер",
      component: "belongs-to",
      relation: "target.coupon.partner",
      resourceName: "partners",
      sortable: false,
      disable: true,
    },

    {
      id: "type",
      label: "Тип",
      component: "enum",
      options: transactionType,
      sortable: false,
      disable: true,
    },

    {
      id: "amount",
      label: "Сумма",
      component: "numeric",
      rules: [rules.required],
      hint: "Положительная сумма -- зачисление, отрицательная -- списание",
    },

    { id: "balance", label: "Баланс", sortable: false, disable: true, component: "numeric" },

    {
      id: "promotion_id",
      label: "Акция",
      component: "belongs-to",
      relation: "promotion",
      resourceName: "promotions",
      sortable: false,
      disable: true,
    },

    {
      id: "receipt_id",
      label: "Чек",
      component: "belongs-to",
      relation: "receipt",
      resourceName: "receipts",
      sortable: false,
      disable: true,
    },

    {
      id: "product_id",
      label: "Товар",
      component: "belongs-to",
      relation: "product",
      resourceName: "products",
      sortable: false,
      disable: true,
    },

    { id: "product_qty", label: "Кол-во", sortable: false, disable: true },

    { id: "comment", label: "Комментарий" },

    {
      id: "created_at",
      label: "Дата создания",
      component: "datetime",
      disable: true,
    },
  ],

  filters: [
    {
      id: "from",
      label: "От",
      component: "datetime",
      clearable: true,
    },

    {
      id: "to",
      label: "До",
      component: "datetime",
      clearable: true,
    },

    {
      id: "target_type",
      label: "Владелец",
      component: "enum",
      options: targetType,
      clearable: true,
    },

    {
      id: "type",
      label: "Тип",
      component: "enum",
      options: transactionType,
      multiple: true,
      clearable: true,
    },
  ],
};
