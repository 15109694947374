<script>
import resources from "../resources";
import components from "./form";
import _ from "lodash";
import { difference, value } from '@/utils';

export default {
  name: "ResourceListFilters",
  components,
  props: {
    resourceName: {
      type: String,
      required: true,
    },

    data: {
      type: Object,
      required: true,
    },

    view: String,
  },

  data: () => ({
    isShowing: false,
  }),

  computed: {
    resource() {
      return resources[this.resourceName];
    },

    fields() {
      return ((this.view ? this.resource.views[this.view] : this.resource).filters || []).filter(
        f => value(f.disable, this.data) !== true && components[f.component] !== undefined
      );
    },

    defaultData() {
      return this.resource.filterDefaults(this.view);
    },

    filtered() {
      return !_.isEmpty(difference(this.data, this.defaultData));
    },
  },

  methods: {
    reset() {
      this.$emit("reset");
      this.isShowing = false;
    },
  },
};
</script>

<template>
  <v-menu
    v-if="fields.length"
    v-model="isShowing"
    max-width="400"
    :close-on-content-click="false"
    bottom
    right
    offset-y
  >
    <template v-slot:activator="{ on }">
      <slot :on="on" :filtered="filtered" />
    </template>

    <v-card>
      <v-list>
        <v-list-item v-for="field in fields" :key="field.id" class="filter-field">
          <v-list-item-content>
            <v-list-item-title>{{ field.label }}</v-list-item-title>

            <component
              v-bind="field"
              :is="field.component || 'string'"
              :value="data[field.id]"
              :data="data"
              @input="$emit('update', field, $event)"
            />
          </v-list-item-content>
        </v-list-item>
      </v-list>

      <v-card-actions>
        <v-btn color="primary" text @click="isShowing = false">Закрыть</v-btn>
        <v-btn v-show="filtered" color="secondary" text @click="reset">Сбросить</v-btn>
      </v-card-actions>
    </v-card>
  </v-menu>
</template>
