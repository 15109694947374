import BooleanField from "./BooleanField";
import DateField from "./DateField";
import DateTimeField from "./DateTimeField";
import BelongsToField from "./BelongsToField";
import ImageField from "./ImageField";
import StringField from "./StringField";
import EnumField from "./EnumField";
import ComputedField from "./ComputedField";
import BelongsToManyNested from "./BelongsToManyNested";
import MorphToField from "./MorphToField";
import TextareaField from "./TextareaField";
import CustomElement from './CustomElement.vue';

export default {
  boolean: BooleanField,
  date: DateField,
  datetime: DateTimeField,
  "belongs-to": BelongsToField,
  photo: ImageField,
  string: StringField,
  enum: EnumField,
  computed: ComputedField,
  "belongs-to-many-nested": BelongsToManyNested,
  "morph-to": MorphToField,
  primary: StringField,
  numeric: StringField,
  "text-input": TextareaField,
  "custom-element": CustomElement,
};
