<script>
import FormInput from "../../mixins/FormInput";

export default {
  name: "BooleanInput",
  extends: FormInput,
};
</script>

<template>
  <v-switch v-bind="inputOptions" :input-value="value" class="mt-0" @change="$emit('input', !!$event)" />
</template>
