<script>
import auth from "../plugins/auth";

export default {
  async beforeRouteEnter(to, from, next) {
    if (auth.isAuthenticated()) {
      try {
        await auth.logout();

        next();
      } catch {
        next(false);
      }
    } else {
      next(false);
    }
  },
};
</script>

<template>
  <div class="display-1">Вы вышли.</div>
</template>
