<script>
import _ from "lodash";
import components from "./reports";

export default {
  name: "DashboardRow",
  components,
  props: {
    items: Array,
    data: null,
    title: null,
  },

  methods: {
    getValue(i) {
      return _.get(this.data, i.id);
    },
  },
};
</script>

<template>
  <div>
    <v-skeleton-loader v-if="title" :loading="!$store.state.initialized" type="heading">
      <div class="title mt-5">{{ title }}</div>
    </v-skeleton-loader>

    <v-row>
      <v-col v-for="i in items" :key="i.id">
        <v-skeleton-loader :loading="!data || !$store.state.initialized" type="article">
          <component v-bind="i" :is="i.component" :value="getValue(i)">{{ i.title }}</component>
        </v-skeleton-loader>
      </v-col>
    </v-row>
  </div>
</template>
