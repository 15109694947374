import StringInput from "./StringInput";
import DateInput from "./DateInput";
import EnumInput from "./EnumInput";
import BelongsToManyNested from "./BelongsToManyNested";
import BooleanInput from "./BooleanInput";
import BelongsToInput from "./BelongsToInput";
import MorphToInput from "./MorphToInput";
import TimeInput from "./TimeInput";
import DateTimeInput from "./DateTimeInput";
import TextareaInput from "./TextareaInput";

export default {
  string: StringInput,
  date: DateInput,
  enum: EnumInput,
  "belongs-to-many-nested": BelongsToManyNested,
  boolean: BooleanInput,
  "belongs-to": BelongsToInput,
  "morph-to": MorphToInput,
  "time-input": TimeInput,
  datetime: DateTimeInput,
  numeric: StringInput,
  "text-input": TextareaInput,
};
