<script>
export default {
  name: "DateField",
  inheritAttrs: false,
  props: ["value"],
};
</script>

<template functional>
  <span v-if="props.value">{{ props.value | format("date") }}</span>
  <span v-else>&mdash;</span>
</template>
