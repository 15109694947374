<script>
export default {
  name: "ImageField",
  inheritAttrs: false,
  props: ["value"],
  data: () => ({
    show: false,
  }),
};
</script>

<template>
  <span v-if="!value">&mdash;</span>
  <span v-else>
    <v-dialog v-model="show">
      <template v-slot:activator="{ on }">
        <v-img :src="value" max-height="125" max-width="125" contain v-on="on" />
      </template>

      <v-sheet>
        <v-img :src="value" max-height="90vh" contain @click="show = false" />
      </v-sheet>
    </v-dialog>

    <v-btn :href="value" class="mt-3" target="_blank" small outlined rounded>
      <v-icon small>mdi-open-in-new</v-icon>
      Открыть
    </v-btn>
  </span>
</template>
