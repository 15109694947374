<script>
import _ from "lodash";

export default {
  name: "SummaryReport",
  props: {
    value: null,
    color: null,
    inverse: Boolean,
  },

  computed: {
    withPrev() {
      return _.isObject(this.value);
    },

    current() {
      return this.withPrev ? (this.value.current && parseFloat(this.value.current)) || 0 : this.value;
    },

    prev() {
      return this.withPrev ? (this.value.prev && parseFloat(this.value.prev)) || 0 : null;
    },

    valueChange() {
      if (!this.withPrev || this.prev === 0) return null;

      return ((this.current - this.prev) / this.prev) * 100.0;
    },

    valueChangeFormatted() {
      return this.valueChange.toFixed(2) + "%";
    },
  },
};
</script>

<template>
  <v-card :color="color" dense>
    <v-card-text>
      <div class="overline font-weight-medium"><slot /></div>

      <p v-if="current" class="headline">{{ current }}</p>
      <p class="headline" v-else>Нет данных</p>

      <template v-if="withPrev">
        <div v-if="valueChange < 0" :class="{ 'green--text': inverse, 'red--text': !inverse }">
          <v-icon :color="inverse ? 'green' : 'red'" small>
            mdi-arrow-down
          </v-icon>
          {{ valueChangeFormatted }}
        </div>

        <div v-else-if="valueChange > 0" :class="{ 'green--text': !inverse, 'red--text': inverse }">
          <v-icon :color="inverse ? 'red' : 'green'" small>
            mdi-arrow-up
          </v-icon>
          {{ valueChangeFormatted }}
        </div>

        <div v-else-if="valueChange === 0">Без изменений</div>

        <div v-else>Нет данных</div>
      </template>

      <div v-else>За все время</div>
    </v-card-text>
  </v-card>
</template>
