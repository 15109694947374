import rules from "../core/rules";
import store from "../store";
import { DownloadFile } from "../core/actionResults";
import { couponActivationState } from './enums';

export default {
  title: "Купоны",
  singularTitle: "Купон",
  name: "code",
  icon: "mdi-ticket-outline",

  fields: [
    { label: "ID", id: "id", component: "primary" },
    { label: "Код", id: "code" },
    { label: "Фото", id: "image_url", component: "photo" },

    {
      id: "activation_state",
      label: "Состояние",
      component: "enum",
      options: couponActivationState,
    },

    {
      id: "client_id",
      label: "Участник",
      component: "belongs-to",
      resourceName: "clients",
      relation: "client",
    },

    {
      id: "partner_id",
      label: "Партнер",
      component: "belongs-to",
      resourceName: "partners",
      relation: "partner",
      disable: true,
    },

    { label: "Дата активации", id: "activated_at", component: "date" },
    { label: "Дата создания", id: "created_at", component: "date" },
    { label: "Дата обновления", id: "updated_at", component: "date" },
  ],

  filters: [
    {
      id: "activation_state",
      label: "Состояние",
      component: "enum",
      options: couponActivationState,
      clearable: true,
    },
  ],

  actions: [
    {
      id: "generate",
      title: "Генерировать промокоды",
      index: true,
      fields: [
        {
          id: "series_start",
          label: "Начальная серия",
          inputType: "number",
          default: 1,
          rules: [rules.required],
        },

        {
          id: "series_count",
          label: "Количество серий",
          inputType: "number",
          default: 50,
          rules: [rules.required],
        },

        {
          id: "number_start",
          label: "Начальный номер",
          inputType: "number",
          default: 1,
          rules: [rules.required],
        },

        {
          id: "number_count",
          label: "Количество номеров",
          inputType: "number",
          default: 50,
          rules: [rules.required],
        },

        {
          id: "text",
          label: "Текст промокода",
          default: function() {
            return store.state.settings.coupons.qrText;
          },
          rules: [rules.required],
        },
      ],
      handler: async function(api, params) {
        return new DownloadFile(await api.post("coupons/generate", params, { responseType: "blob" }), "coupons.csv");
      },
    },

    {
      id: "decline",
      title: "Отклонить регистрацию",
      icon: "mdi-cancel",
      color: "warning",

      hide() {
        return this.activation_state !== "pending";
      },

      async handler(api) {
        await api.post(`coupons/${this.id}/decline`);
      },
    },

    {
      id: "check",
      title: "Перепроверить привязку к партнеру",

      hide() {
        return this.activation_state !== "pending";
      },

      async handler(api) {
        const resp = await api.post(`coupons/${this.id}/check`);

        return `К купону привязан партнер ${resp.data.name}`;
      },
    },
  ],
};
