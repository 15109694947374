import rules from "../core/rules";
import { productPatternType } from './enums';

export default {
  url: "products/patterns",
  title: "Мэтчеры товаров",
  singularTitle: "Мэтчэр товара",
  permissions: {
    create: true,
    delete: true,
  },

  fields: [
    { id: "id", label: "ID", component: "primary" },

    {
      id: "product_id",
      label: "Товар",
      component: "belongs-to",
      resourceName: "products",
      relation: "product",
      rules: [rules.required],
      sortable: false,
    },

    {
      id: "type",
      label: "Тип",
      component: "enum",
      options: productPatternType,
      rules: [rules.required],
      default: "matchExternalId",
      sortable: false,
    },

    { id: "value", label: "Значение", rules: [rules.required] },
    { id: "is_active", label: "Активен", component: "boolean", default: true },
  ],
};
