import Vue from "vue";
import Vuex from "vuex";
import _ from "lodash";
import api from "../plugins/api";
import resources from "../resources";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    accessToken: null,
    initialized: false,

    user: {
      name: null,
      email: null,
    },

    resources: {},

    settings: {
      companyName: null,
    },
  },

  mutations: {
    updateProfile(state, data) {
      state.initialized = true;
      state.user = data.user;
      state.settings = data.settings;
    },

    initResource(state, resourceName) {
      Vue.set(state.resources, resourceName, {
        loading: true,
        items: [],
      });
    },

    updateResource(state, { resourceName, items }) {
      const resource = state.resources[resourceName];

      resource.loading = false;
      resource.items = items;
    },

    pushResourceItem(state, { resourceName, data }) {
      const resource = resources[resourceName];
      const id = resource.key(data);
      const text = resource.name(data);
      const items = state.resources[resourceName].items;
      const existing = items.find(item => item.id === id);

      if (existing) {
        existing.text = text;
      } else {
        items.push({ id, text });
      }
    },
  },

  actions: {
    async initialize({ commit }) {
      const resp = await api.profile();

      commit("updateProfile", resp.data);

      _.forEach(resp.data.resources, (items, resourceName) => {
        commit("initResource", resourceName);
        commit("updateResource", { resourceName, items });
      });
    },

    async updateProfile({ commit }, data) {
      const resp = await api.updateProfile(data);

      commit("updateProfile", resp.data);
    },

    async initResourceOptions({ state, commit }, resourceName) {
      if (state.resources[resourceName]) return;

      commit("initResource", resourceName);

      const resource = resources[resourceName];

      if (!resource) return;

      const resp = await api.index(resource.url(), { per_page: 100 });

      commit("updateResource", {
        resourceName,
        items: resp.data.map(item => ({
          id: resource.key(item),
          text: resource.name(item),
        })),
      });
    },

    pushResourceItem({ state, commit }, payload) {
      if (state.resources[payload.resourceName]) {
        commit("pushResourceItem", payload);
      }
    },
  },

  modules: {},
});
