import Vue from "vue";
import VueRouter from "vue-router";
import ResourceIndex from "../views/ResourceIndex";
import ResourceShow from "../views/ResourceShow";
import Error404 from "../views/404";
import Error500 from "../views/500";
import goTo from "vuetify/es5/services/goto";
import ResourceEdit from "../views/ResourceEdit";
import ResourceCreate from "../views/ResourceCreate";
import Settings from "../views/SettingsPage.vue";
import Callback from "../views/CallbackPage.vue";
import Auth from "../plugins/auth";
import NotSupported from "../views/NotSupported";
import Logout from "../views/LogoutPage.vue";
import Profile from "../views/ProfilePage.vue";
import Dashboard from "../views/DashboardPage.vue";
import ResourceIndexView from "../views/ResourceIndexView";
import ResourceRelatedCreate from '@/views/ResourceRelatedCreate.vue';
import ResourceRelatedEdit from '@/views/ResourceRelatedEdit.vue';
import ResourceRelatedShow from '@/views/ResourceRelatedShow.vue';

Vue.use(VueRouter);

const routes = [
  { path: "/callback", component: Callback },
  { path: "/not-supported", component: NotSupported },
  { path: "/logout", component: Logout },
  { path: "/404", component: Error404 },
  { path: "/500", component: Error500 },

  {
    path: "/",
    name: "dashboard",
    component: Dashboard,
    meta: { requiresAuth: true },
  },

  { path: "/profile", component: Profile, meta: { requiresAuth: true } },

  {
    path: "/settings/:tab?",
    component: Settings,
    meta: { requiresAuth: true },
  },

  {
    path: "/:resource",
    name: "resourceIndex",
    component: ResourceIndex,
    meta: { requiresAuth: true },
  },

  {
    path: "/:resource/views/:view",
    name: "resourceIndexView",
    component: ResourceIndexView,
    meta: { requiresAuth: true },
  },

  {
    path: "/:resource/create",
    name: "resourceCreate",
    component: ResourceCreate,
    meta: { requiresAuth: true },
  },

  {
    path: "/:resource/:parentKey/:relation/create",
    name: "resourceRelatedCreate",
    component: ResourceRelatedCreate,
    meta: { requiresAuth: true },
  },

  {
    path: "/:resource/:parentKey/:relation/:key/edit",
    name: "resourceRelatedEdit",
    component: ResourceRelatedEdit,
    meta: { requiresAuth: true },
  },

  {
    path: "/:resource/:parentKey/:relation/:key",
    name: "resourceRelatedShow",
    component: ResourceRelatedShow,
    meta: { requiresAuth: true },
  },

  {
    path: "/:resource/:key",
    name: "resourceShow",
    component: ResourceShow,
    meta: { requiresAuth: true },
  },

  {
    path: "/:resource/:key/edit",
    name: "resourceEdit",
    component: ResourceEdit,
    meta: { requiresAuth: true },
  },
];

const router = new VueRouter({
  mode: process.env.VUE_APP_ROUTER_MODE,
  base: process.env.VUE_APP_ROUTER_BASE,
  scrollBehavior: (to, from, savedPosition) => {
    if (to.path === from.path) return;

    let scrollTo = 0;

    if (savedPosition) {
      scrollTo = savedPosition.y;
    }

    return goTo(scrollTo);
  },
  routes,
});

router.beforeEach(function(to, from, next) {
  if (to.matched.some(r => r.meta.requiresAuth) && !Auth.isAuthenticated()) {
    if (Auth.isSupported()) {
      Auth.login(to.fullPath);
    } else {
      router.replace("/not-supported");
    }
  } else {
    next();
  }
});

export default router;
