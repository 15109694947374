<script>
import tabs from "../resources/settings";
import Profile from "./ProfilePage.vue";

export default {
  name: "SettingsPage",
  extends: Profile,

  computed: {
    tabName() {
      return this.$route.params.tab || "main";
    },

    tab() {
      return tabs.find(tab => tab.id === this.tabName);
    },

    fields() {
      return this.tab.fields;
    },

    title() {
      return this.tab.title;
    },

    hint() {
      return this.tab.hint;
    },
  },

  watch: {
    tabName: {
      immediate: true,
      handler: function() {
        this._syncData();
      },
    },
  },
};
</script>
