import { receiptState } from "./enums";

export default [
  {
    items: [
      {
        id: "clients.total_count_all_time",
        title: "Всего участников",
        component: "summary-card",
      },

      {
        id: "clients.active_count_all_time",
        title: "Активных участников",
        component: "summary-card",
      },

      {
        id: "clients.balance_sum_all_time",
        title: "Баланс всех участников",
        component: "summary-card",
        compactNumbers: true,
      },

      {
        id: "clients.active_users_balance_sum_all_time",
        title: "Баланс активных участников",
        component: "summary-card",
        compactNumbers: true,
      },

      {
        id: "partners.balance_sum_all_time",
        title: "Баланс партнеров",
        component: "summary-card",
        compactNumbers: true,
      },
    ],
  },

  {
    items: [
      {
        id: "clients.new_count",
        title: "Новых участников",
        component: "summary-card",
      },

      {
        id: "coupons.activated_count",
        title: "Активировано купонов",
        component: "summary-card",
      },

      {
        id: "partners.created_count",
        title: "Новых партнеров",
        component: "summary-card",
      },
    ],
  },

  {
    items: [
      {
        id: "clients.distribution_by_city",
        title: "Распределение участников по городам",
        component: "summary-table",
        headers: [
          {
            value: "city",
            text: "Город",
          },

          {
            value: "total_count",
            text: "Количество",
          },

          {
            value: "balance_sum",
            text: "Баланс",
          },
        ],
      },
    ],
  },

  {
    title: "Кэшбэк",

    items: [
      {
        id: "receipts.new_count",
        title: "Добавлено фото",
        component: "summary-card",
      },

      {
        id: "receipts.with_cash_back_count",
        title: "Фото с кэшбэком",
        component: "summary-card",
        color: "green lighten-4",
      },

      {
        id: "receipts.no_cash_back_count",
        title: "Фото без кэшбэка",
        component: "summary-card",
        color: "red lighten-5",
        inverse: true,
      },

      {
        id: "receipts.client_cash_back_sum",
        title: "Кэшбэк клиентам",
        component: "summary-card",
        compactNumbers: true,
      },

      {
        id: "receipts.partner_cash_back_sum",
        title: "Кэшбэк партнерам",
        component: "summary-card",
        compactNumbers: true,
      },

      {
        id: "receipts.distribution_by_state",
        title: "Фото по состоянию",
        component: "distribution",
        valueKey: "total_count",
        idKey: "state",
        options: receiptState,
      },
    ],
  },

  {
    items: [
      {
        id: "products.distribution_by_product",
        title: "Кэшбэк по товарам",
        component: "summary-table",
        headers: [
          {
            value: "name",
            text: "Название",
          },

          {
            value: "total_count",
            text: "Кол-во",
            align: "right",
          },

          {
            value: "cash_back_sum",
            text: "Кэшбэк",
            align: "right",
          },

          {
            value: "cash_back_avg",
            text: "Средний кэшбэк",
            align: "right",
          },
        ],
      },

      {
        id: "promotions.distribution_by_promotion",
        title: "Кэшбэк по акциям",
        component: "summary-table",
        headers: [
          {
            value: "name",
            text: "Акция",
          },

          {
            value: "total_count",
            text: "Кол-во",
            align: "right",
          },

          {
            value: "cash_back_sum",
            text: "Кэшбэк",
            align: "right",
          },

          {
            value: "cash_back_avg",
            text: "Средний кэшбэк",
            align: "right",
          },
        ],
      },
    ],
  },

  {
    title: "Диспуты",
    items: [
      {
        id: "disputes.new_count",
        title: "Добавлено диспутов",
        component: "summary-card",
        inverse: true,
      },

      {
        id: "disputes.with_change_count",
        title: "Диспутов с доначислениями",
        component: "summary-card",
      },

      {
        id: "disputes.cash_back_change_sum",
        title: "Сумма доначислений",
        component: "summary-card",
        compactNumbers: true,
      },
    ],
  },

  {
    title: "Выплаты",
    items: [
      {
        id: "payouts.total_count",
        title: "Количество",
        component: "summary-card",
      },

      {
        id: "payouts.amount_sum",
        title: "Сумма",
        component: "summary-card",
        compactNumbers: true,
      },

      {
        id: "payouts.amount_avg",
        title: "Средняя сумма",
        component: "summary-card",
      },
    ],
  },

  {
    items: [
      {
        id: "payouts.distribution_by_destination",
        title: "Выплаты по типу",
        component: "summary-table",
        headers: [
          {
            value: "destination_type",
            text: "Тип выплаты",
          },

          {
            value: "total_count",
            text: "Кол-во",
            align: "right",
          },

          {
            value: "amount_sum",
            text: "Сумма",
            align: "right",
          },

          {
            value: "amount_avg",
            text: "Средняя сумма",
            align: "right",
          },
        ],
      },
    ],
  },
];
