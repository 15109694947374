import rules from "../core/rules";

export default [
  { id: "user.name", label: "Имя пользователя", rules: [rules.required] },

  {
    id: "user.email",
    label: "E-mail",
    inputType: "email",
    rules: [rules.required],
  },

  {
    id: "user.password",
    label: "Новый пароль",
    inputType: "password",
    getter: () => {},
  },

  {
    id: "user.password_confirmation",
    label: "Повторите пароль",
    inputType: "password",
    getter: () => {},
    disable: function() {
      return !this.user.password;
    },
    rules: [rules.required],
  },

  {
    id: "user.old_password",
    label: "Старый пароль",
    inputType: "password",
    getter: () => {},
    disable: function() {
      return !this.user.password;
    },
    rules: [rules.required],
  },
];
