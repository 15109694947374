<script>
import FormInput from "../../mixins/FormInput";
import format from "../../formatters";
import moment from "moment";

export default {
  name: "DateTimeInput",
  extends: FormInput,
  props: ["seconds"],

  data: () => ({
    show: false,
  }),

  computed: {
    formattedValue() {
      return this.value && format(this.value, "datetime");
    },

    date() {
      return this.value && moment(this.value).format("YYYY-MM-DD");
    },

    time() {
      return this.value && moment(this.value).format(this.seconds ? "HH:mm:ss" : "HH:mm");
    },
  },

  methods: {
    updateDate(v) {
      this.$emit("input", moment(this.time ? `${v} ${this.time}` : v).format("YYYY-MM-DDTHH:mm:ssZZ"));
    },

    updateTime(v) {
      const date = this.date || moment().format("YYYY-MM-DD");

      this.$emit("input", moment(`${date} ${v}`).format("YYYY-MM-DDTHH:mm:ssZZ"));
    },
  },
};
</script>

<template>
  <v-menu v-model="show" :close-on-content-click="false" transition="scale-transition" offset-y min-width="290px">
    <template v-slot:activator="{ on }">
      <v-text-field v-bind="inputOptions" :value="formattedValue" :clearable="clearable" readonly v-on="on" />
    </template>

    <v-card>
      <v-card-text>
        <v-row>
          <v-col cols="12" sm="6">
            <v-date-picker :value="date" width="290" @input="updateDate" />
          </v-col>

          <v-col cols="12" sm="6">
            <v-time-picker :value="time" :use-seconds="seconds" format="24hr" @input="updateTime" />
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-actions>
        <v-btn @click="show = false">Закрыть</v-btn>
      </v-card-actions>
    </v-card>
  </v-menu>
</template>
