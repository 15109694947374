export default {
  required: value => value === 0 || !!value || "Это поле обязательно для заполнения",

  min(limit) {
    return value => {
      value = parseFloat(value);

      return isNaN(value) || value >= limit || `Значение не должно быть меньше ${limit}`;
    };
  },

  digits: value => /^\d*$/.test(value) || "Это поле должно содержать только цифры",
  integer: value => /^-?\d*$/.test(value) || "Это поле должно быть целым числом",
};
