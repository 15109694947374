<script>
import _ from "lodash";
import ResourceList from "../components/ResourceList";
import resources from "../resources";

export default {
  name: "ResourcePage",
  components: { ResourceList },
  computed: {
    resourceName() {
      return this.$route.params.resource;
    },

    resource() {
      return resources[this.resourceName];
    },

    views() {
      return _.map(this.resource.views, (r, id) => ({ id, title: r.title }));
    },
  },
};
</script>

<template>
  <ResourceList v-if="$store.state.initialized" :resource-name="$route.params.resource" :key="$route.params.resource">
    <template v-slot:header v-if="views.length > 0">
      <v-menu>
        <template v-slot:activator="{ on }">
          <v-btn class="mr-3" text v-on="on">Дополнительно</v-btn>
        </template>

        <v-card>
          <v-list>
            <v-list-item
              v-for="view in views"
              :to="{ name: 'resourceIndexView', params: { resource: resourceName, view: view.id } }"
              :key="view.id"
              link
            >
              <v-list-item-title>{{ view.title }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-card>
      </v-menu>
    </template>
  </ResourceList>
  <v-skeleton-loader v-else type="table" />
</template>
