<script>
import resources from "../../resources";

export default {
  name: "PrimaryField",
  props: ["value", "dataResourceName"],
  computed: {
    resource() {
      return resources[this.dataResourceName];
    },

    link() {
      return this.resource.path(this.value);
    },
  },
};
</script>

<template>
  <router-link v-if="resource.allows('view')" :to="link">
    {{ value }}
  </router-link>
  <span v-else>{{ value }}</span>
</template>
