<script>
import _ from "lodash";
import ResourcePage from "@/mixins/ResourcePage";
import FormFields from "@/components/FormFields";
import { difference, value } from '@/utils';
import FlashMessage from "@/components/FlashMessage";
import ConfirmationDialog from "@/components/ConfirmationDialog";
import ResourceForm from "@/mixins/ResourceForm";
import fields from "@/components/form";

export default {
  name: "ResourceEdit",
  components: { ConfirmationDialog, FlashMessage, FormFields },
  extends: ResourcePage,
  mixins: [ ResourceForm ],

  data: () => ({
    clickedButton: null,
    initialData: null,
  }),

  beforeRouteLeave(to, from, next) {
    if (!_.isEmpty(this._diff())) {
      this._next = next;
      this.closeConfirmation = true;
    } else {
      next();
    }
  },

  computed: {
    fields() {
      return this.resource.fields.filter((field) => {
        return (
          value(field.disableForUpdate, this.data) !== true &&
          value(field.disable, this.data) !== true &&
          (fields[field.component || "string"] !== undefined || field.component === "header") &&
          !this._filterField(field)
        );
      });
    },

    breadcrumbs() {
      return [
        {
          text: this.resource.title,
          to: this.resource.allows('viewAny') && this.resource.path(),
          exact: true,
        },

        {
          text: this.resource.name(this.data),
          to: this.resource.allows('view', this.data) && this.resource.path(this.data),
          exact: true,
        },

        { text: "Редактирование" },
      ];
    },
  },

  watch: {
    initialData (v) {
      if (!this.resource.allows("update", v)) this.$router.replace(this._redirectUrl());
    },
  },

  methods: {
    _updateData(resp) {
      this.initialData = JSON.parse(JSON.stringify(resp.data));
      this.data = resp.data;
    },

    _handleSaved(close) {
      if (close) {
        this.$router.replace(this._redirectUrl());
      } else {
        this.flash("Сохранено!");
      }
    },

    _redirectUrl() {
      return this.resource.path(this.data);
    },

    async save(ev, close) {
      if (!this.validate()) return;

      const diff = this._diff();

      if (_.isEmpty(diff)) {
        this._handleSaved(close);

        return;
      }

      try {
        this.loading = true;
        this.clickedButton = close ? "close" : "save";

        const resp = await this._xhr(diff);

        await this.$store.dispatch("pushResourceItem", {
          resourceName: this.resourceName,
          data: resp.data,
        });

        this._updateData(resp);
        this._handleSaved(close);
      } catch (err) {
        this._handleError(err);
      } finally {
        this.loading = false;
      }
    },

    saveAndClose(ev) {
      this.save(ev, true);
    },

    _xhr(data) {
      return this.$api.patch(this.resource.url(this.key), data);
    },

    _diff() {
      const data = difference(this.data, this.initialData);

      this.fields.forEach(field => {
        if (field.component === "belongs-to-many-nested") {
          if (this._relationsDifferent(field.id)) {
            data[field.id] = this.data[field.id];
          } else {
            delete data[field.id];
          }
        }
      });

      return data;
    },

    _relationsDifferent(key) {
      const a = this.data[key];
      const b = this.initialData[key];

      return !b || !a || a.length !== b.length || difference(a, b).length > 0;
    },

    _filterField () {
      return false;
    },
  },
};
</script>

<template>
  <v-skeleton-loader :loading="!$store.state.initialized" type="card">
    <v-breadcrumbs :items="breadcrumbs" class="pl-0" large />

    <v-form ref="form">
      <FormFields
        :loading="loading"
        :title="`${resource.singularTitle}: редактирование`"
        :fields="fields"
        :data="data"
        :errors="errors"
        @update="clearFieldErrors"
      >
        <template v-slot:footer>
          <v-card-actions>
            <v-btn
              :disabled="loading"
              :loading="loading && clickedButton === 'close'"
              color="primary"
              text
              @click="saveAndClose"
            >
              Сохранить и закрыть
            </v-btn>

            <v-btn :disabled="loading" :loading="loading && clickedButton === 'save'" color="accent" text @click="save">
              <v-icon class="d-sm-none">save</v-icon>
              <span class="d-none d-sm-inline">Сохранить</span>
            </v-btn>

            <v-btn :disabled="loading" text @click="$router.back()">
              <v-icon class="d-sm-none">close</v-icon>
              <span class="d-none d-sm-inline">Закрыть</span>
            </v-btn>
          </v-card-actions>
        </template>
      </FormFields>
    </v-form>

    <FlashMessage v-model="message" :color="messageColor" />

    <ConfirmationDialog v-model="closeConfirmation" @confirm="_next()" @cancel="_next(false)">
      Вы вносили изменения в форму, действительно хотите закрыть?
    </ConfirmationDialog>
  </v-skeleton-loader>
</template>
