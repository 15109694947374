import _ from "lodash";
import { saveAs } from "file-saver";
import date from "../formatters/date";
import datetime from "../formatters/datetime";
import resources from "../resources";
import store from "../store";

function baseBelongsTo(resourceName, value, data) {
  const relationValue = _.get(data, this.relation);
  const resource = resources[resourceName];

  if (relationValue) {
    return resource.name(relationValue);
  }

  return (((store.state.resources[resourceName] || {}).items || []).find(i => i.id === value) || {}).text || value;
}

export const formatters = {
  date,
  datetime,

  enum(value) {
    const option = (this.options || []).find(o => o.value === value);

    return (option || {}).text || value;
  },

  boolean(value) {
    return value === undefined || value === null || value === "" ? null : value ? 1 : 0;
  },

  "belongs-to"(value, data) {
    return baseBelongsTo.call(this, this.resourceName, value, data);
  },

  "morph-to"(value, data) {
    return baseBelongsTo.call(this, this.morphMap[data[this.morphKeyName]], value, data);
  },

  numeric(value) {
    return (value || "").toString().replace(".", ",");
  },
};

export class CSV {
  constructor(headers, delimiter = ";") {
    this.headers = headers;
    this.delimiter = delimiter;
    this.content = this._renderHeader();
  }

  push(items) {
    items.forEach(i => {
      this.content += this._renderRow(this.headers.map(h => this._headerValue(h, i)));
    });
  }

  save(filename) {
    saveAs(new Blob([this.content], { type: "text/csv;charset=utf-8;" }), filename);
  }

  _headerValue(header, value) {
    const colValue = typeof header.getter === "function" ? header.getter.call(value) : _.get(value, header.id);

    const formatter = formatters[header.component];

    return formatter ? formatter.call(header, colValue, value) : colValue;
  }

  _renderHeader() {
    return this._renderRow(this.headers.map(h => h.label || h.id));
  }

  _renderRow(row) {
    return row.map(v => this._mapValue(v)).join(this.delimiter) + "\n";
  }

  _mapValue(value) {
    value = ((value && value.toString()) || "").replace(/"/g, '""');

    if (value.search(/([",\n])/g) >= 0) {
      value = '"' + value + '"';
    }

    return value;
  }
}
