<script>
export default {
  name: "DateTimeField",
  inheritAttrs: false,
  props: ["value"],
};
</script>

<template functional>
  <span v-if="props.value" class="text-no-wrap">
    <span class="text-no-wrap">{{ props.value | format("date") }}</span>
    <span class="text-no-wrap"> {{ props.value | format("time") }}</span>
  </span>
  <span v-else>&mdash;</span>
</template>
