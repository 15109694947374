<script>
export default {
  name: "SummaryTableReport",
  props: {
    value: Array,
    headers: Array,
  },
};
</script>

<template>
  <v-card>
    <v-card-text class="overline"><slot /></v-card-text>

    <v-data-table :items="value" :headers="headers" disable-pagination hide-default-footer />
  </v-card>
</template>
