import moment from "moment";
import rules from "../core/rules";

function configField(field, scenarioType, extraFilter) {
  const matchScenario = function() {
    return this.scenario_type !== scenarioType || (typeof extraFilter === "function" && extraFilter.apply(this));
  };

  return {
    id: "config." + field,
    hideFromIndex: true,
    disable: matchScenario,
    hideFromDetails: matchScenario,
  };
}

function notActive() {
  return !(this.is_active && moment(this.starts_at).isBefore());
}

export default {
  title: "Акции",
  singularTitle: "Акция",
  name: "name",
  icon: "mdi-gift",
  permissions: {
    create: true,
    update: notActive,
    delete: notActive,
  },

  rowClass() {
    return this.is_active && moment(this.ends_at).isAfter() && moment(this.starts_at).isBefore()
      ? "green lighten-5"
      : null;
  },

  fields: [
    { label: "ID", id: "id", component: "primary" },
    { label: "Название", id: "name", max: 255, rules: [rules.required] },

    {
      id: "is_active",
      label: "Запустить",
      component: "boolean",
      hint: "После запуска редактировать акцию будет нельзя",
      hide: true,
    },

    {
      id: "state",
      label: "Состояние",
      disable: true,
      component: "enum",
      sortable: false,
      getter: function() {
        if (!this.is_active) return "draft";
        if (!moment(this.ends_at).isAfter()) return "finished";
        if (moment(this.starts_at).isAfter()) return "not_started";
        return "active";
      },
      options: [
        { value: "draft", text: "Черновик" },
        { value: "active", text: "Активна", color: "green" },
        { value: "finished", text: "Завершена", color: "error" },
        { value: "not_started", text: "Не начата", color: "yellow" },
      ],
    },

    {
      id: "picture",
      label: "Картинка",
      hint: "URL на картинку",
      max: 1000,
      hideFromIndex: true,
      hideFromDetails: true,
    },

    {
      id: "alt_picture",
      label: "Доп. картинка",
      hint: "URL на картинку",
      max: 1000,
      hideFromIndex: true,
      hideFromDetails: true,
    },

    {
      id: "picture",
      label: "Картинка",
      component: "photo",
      sortable: false,
    },

    {
      id: "alt_picture",
      label: "Доп. картинка",
      component: "photo",
      sortable: false,
    },

    {
      id: "short_description",
      label: "Краткое описание",
      component: "text-input",
      max: 1000,
      sortable: false,
    },

    {
      id: "description",
      label: "Описание",
      component: "text-input",
      max: 65535,
      sortable: false,
      hideFromIndex: true,
    },

    {
      id: "target_type",
      label: "Кому кэшбэк",
      component: "enum",
      options: [
        { text: "Участнику", value: "client", color: "green" },
        { text: "Партнеру", value: "partner", color: "yellow" },
      ],
      rules: [rules.required],
      sortable: false,
    },

    {
      id: "scenario_type",
      label: "Механика",
      component: "enum",
      options: [
        { text: "На все позиции", value: "basic" },
        { text: "На позиции с периодизацией", value: "periodicWithLimits" },
        { text: "На первую покупку клиентом", value: "clientFirstBuy" },
        { text: "На чек", value: "receipt" },
        { text: "В день рождения", value: "birthday" },
      ],
      rules: [rules.required],
      sortable: false,
    },

    {
      id: "products",
      label: "Товары, участвующие в акции",
      component: "belongs-to-many-nested",
      resourceName: "products",
      hideFromIndex: true,
      staticFilter: {
        type: "promotional",
      },
      disable: function () { return this.scenario_type === 'birthday'; },
    },

    {
      id: "parent_promotion_id",
      label: "Зависит от акции",
      hint: "Если указано, то кэшбэк начисляется только в случае, если были начисления по указанной акции",
      component: "belongs-to",
      resourceName: "promotions",
      relation: "parent",
      sortable: false,
      hideFromIndex: true,
      disable: function () { return this.scenario_type === 'birthday'; },
      //hide: true,
    },

    {
      label: "Период учета лимитов",
      inputType: "number",
      suffix: "Дней",
      hint: "Период начинается с момента первого начисления кэшбэка",
      rules: [rules.required, rules.min(1)],
      ...configField("period", "periodicWithLimits"),
    },

    {
      label: "Максимально периодов",
      inputType: "number",
      hint:
        "Максимальное количество периодов с момента первого начисления кэшбэка." +
        " Если указать период = 30, а периодов = 1, то акция будет работать только первые 30 дней.",
      rules: [rules.min(0)],
      ...configField("max_periods", "periodicWithLimits"),
    },

    {
      label: "Макс. товаров за период",
      inputType: "number",
      suffix: "шт",
      rules: [rules.min(0)],
      ...configField("max_qty", "periodicWithLimits"),
    },

    {
      label: "Макс. сумма кэшбэка за период",
      inputType: "number",
      suffix: "баллов",
      rules: [rules.min(0)],
      ...configField("max_amount", "periodicWithLimits"),
    },

    {
      label: "Макс. кол-во чеков на клиента",
      inputType: "number",
      rules: [rules.min(0)],
      ...configField("max_client_receipts", "receipt", function() {
        return this.target_type !== "partner";
      }),
    },

    {
      id: "cash_back_type",
      label: "Начисление кэшбэка",
      component: "enum",
      options: [
        { text: "Процент от суммы", value: "percentage" },
        { text: "Указанная сумма", value: "hardcoded" },
      ],
      hide: true,
      rules: [rules.required],
    },

    {
      id: "cash_back_value",
      label: "Значение кэшбэка",
      hide: true,
      inputType: "number",
      rules: [rules.required],
    },

    {
      id: "cash_back",
      label: "Кэшбэк",
      component: "computed",
      getter: function() {
        switch (this.cash_back_type) {
          case "percentage":
            return this.cash_back_value + "%";

          case "hardcoded":
            return this.cash_back_value + " баллов";
        }
      },
    },

    {
      label: "Дата начала",
      id: "starts_at",
      component: "date",
      rules: [rules.required],
    },

    {
      label: "Дата завершения",
      id: "ends_at",
      component: "date",
      endOfDay: true,
      rules: [rules.required],
    },

    {
      id: "created_at",
      label: "Дата создания",
      component: "date",
      disable: true,
    },
  ],

  actions: [
    {
      id: "stop",
      title: "Завершить акцию",

      hide() {
        return moment(this.ends_at).isBefore() || !this.is_active;
      },

      handler: async function(api) {
        await api.post(`promotions/${this.id}/stop`);

        return "Акция остановлена!";
      },
    },

    {
      id: "prolong",
      title: "Продлить акцию",

      hide() {
        return moment(this.ends_at).isBefore() || !this.is_active;
      },

      fields: [
        {
          id: "ends_at",
          label: "Дата завершения",
          default() {
            return this.ends_at;
          },
          component: "date",
          endOfDay: true,
          rules: [rules.required],
        },
      ],

      handler: async function(api, params) {
        await api.post(`promotions/${this.id}/prolong`, params);

        return "Акция продлена!";
      },
    },
  ],
};
