<script>
export default {
  name: "TextareaField",
  props: ["value"],
};
</script>

<template>
  <v-textarea v-if="value" :value="value" solo dense flat outlined readonly auto-grow :rows="1" />
  <span v-else>&mdash;</span>
</template>
