<script>
import moment from "moment";
import FlashesMessages from "../mixins/FlashesMessages";
import rows from "../resources/dashboard";
import DashboardRow from "../components/DashboardRow";
import DateInput from "../components/form/DateInput";
import { predefinedPeriods } from '@/resources/enums';

export default {
  name: "DashboardPage",
  components: { DateInput, DashboardRow },
  mixins: [FlashesMessages],

  data: () => ({
    data: null,
    loading: false,
    predefinedPeriod: "this_week_so_far",
    from: null,
    to: null,
  }),

  computed: {
    periods() {
      return predefinedPeriods;
    },

    period() {
      return moment(this.from).format("DD.MM.YYYY") + "-" + moment(this.to).format("DD.MM.YYYY");
    },
  },

  watch: {
    predefinedPeriod(v) {
      if (v) this.load();
    },

    period() {
      if (!this.predefinedPeriod) this.load();
    },
  },

  created() {
    this.load();

    this.rows = rows;
  },

  methods: {
    async load() {
      try {
        this.loading = true;

        const data = {};

        if (this.predefinedPeriod) {
          data.predefined_period = this.predefinedPeriod;
          data.base = moment().toISOString(true);
        } else {
          data.from = this.from;
          data.to = this.to;
        }

        const resp = await this.$api.get("/reports", data);

        this.data = resp.data;
        this.from = resp.meta.period.from;
        this.to = resp.meta.period.to;
      } catch (err) {
        this.flash("Ошибка");
      } finally {
        this.loading = false;
      }
    },

    updateTo(value) {
      this.to = moment(value)
        .endOf("day")
        .toISOString(true);
    },

    editPeriod() {
      this.predefinedPeriod = null;
      this.$nextTick(function() {
        this.$refs.from.show();
      });
    },
  },
};
</script>

<template>
  <div>
    <v-row dense>
      <v-col cols="12" sm="6" lg="4" xl="3">
        <v-skeleton-loader :loading="!$store.state.initialized" type="heading">
          <v-select
            v-model="predefinedPeriod"
            :items="periods"
            :loading="loading"
            placeholder="Период"
            solo
            dense
            outlined
          />
        </v-skeleton-loader>
      </v-col>

      <v-col v-if="predefinedPeriod" cols="12" sm="3" lg="3" xl="2">
        <v-skeleton-loader :loading="!$store.state.initialized" type="heading">
          <v-text-field :value="period" readonly solo dense outlined @click="editPeriod" />
        </v-skeleton-loader>
      </v-col>

      <v-col v-if="!predefinedPeriod" cols="12" sm="3" lg="3" xl="2">
        <DateInput v-model="from" ref="from" />
      </v-col>

      <v-col v-if="!predefinedPeriod" cols="12" sm="3" lg="3" xl="2">
        <DateInput :value="to" @input="updateTo" />
      </v-col>

      <v-col v-if="loading" cols="12" sm="1" lg="1" xl="1">
        <v-progress-circular indeterminate color="primary" />
      </v-col>
    </v-row>

    <DashboardRow v-for="(row, i) in rows" v-bind="row" :key="i" :data="data" />
  </div>
</template>
