import date from "./date";
import datetime from "./datetime";
import time from "./time";

const formats = {
  date,
  datetime,
  time,
};

function format(value, format) {
  if (!format || !formats[format]) return value;

  return formats[format](value);
}

export default format;
