import { couponsService } from "../enums";
import rules from "../../core/rules";

function pharmaHrmNotUsed() {
  return this.settings.coupons.service !== "pharmahrm";
}

export default {
  id: "coupons",
  title: "Купоны",
  fields: [
    {
      id: "settings.coupons.service",
      label: "Сервис",
      component: "enum",
      clearable: true,
      placeholder: "Без купонов",
      options: couponsService,
    },

    {
      id: "settings.coupons.qrText",
      label: "Текст для QR-кодов по-умолчанию",
      hint: "Текст должен содержать плейсхолдер {code} -- вместо него будет подставлен промокод",
    },

    {
      id: "settings.coupons.pharmahrm",
      label: "Настройки PharmaHRM",
      component: "header",
      disable: pharmaHrmNotUsed,
    },

    {
      id: "settings.coupons.pharmahrm.domain",
      label: "Домен",
      disable: pharmaHrmNotUsed,
      rules: [rules.required],
    },

    {
      id: "settings.coupons.pharmahrm.login",
      label: "Логин",
      disable: pharmaHrmNotUsed,
      rules: [rules.required],
    },

    {
      id: "settings.coupons.pharmahrm.password",
      label: "Пароль",
      disable: pharmaHrmNotUsed,
      inputType: "password",
      rules: [rules.required],
    },
  ],
};
