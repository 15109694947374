<script>
import moment from "moment";
import format from "../../formatters";
import FormInput from "../../mixins/FormInput";

export default {
  name: "DateInput",
  extends: FormInput,
  props: ["endOfDay"],

  data: () => ({
    isActive: false,
  }),

  computed: {
    formattedValue() {
      return this.value && format(this.value, "date");
    },

    pickerValue() {
      return this.value && moment(this.value).format("YYYY-MM-DD");
    },
  },

  methods: {
    input(value) {
      value = moment(value);

      if (this.endOfDay) {
        value = value.endOf("day");
      }

      this.$emit("input", value.format("YYYY-MM-DDTHH:mm:ssZZ"));

      this.isActive = false;
    },

    show() {
      this.isActive = true;
    },
  },
};
</script>

<template>
  <v-menu
    v-model="isActive"
    :close-on-content-click="false"
    transition="scale-transition"
    offset-y
    max-width="290px"
    min-width="290px"
  >
    <template v-slot:activator="{ on }">
      <v-text-field v-bind="inputOptions" :value="formattedValue" :clearable="clearable" readonly v-on="on" />
    </template>

    <v-date-picker :value="pickerValue" no-title @input="input" />
  </v-menu>
</template>
