<script>
import fields from "./details";
import HasGetter from "../mixins/HasGetter";
import FieldWrap from "./FieldWrap";

export default {
  name: "FieldList",
  mixins: [HasGetter],
  components: { ...fields, FieldWrap },
  props: {
    title: {
      type: String,
      required: true,
    },

    fields: {
      type: Array,
      required: true,
    },

    data: {
      type: Object,
      required: true,
    },

    loading: Boolean,
  },
};
</script>

<template>
  <v-card :loading="loading">
    <v-card-title class="grey lighten-4">
      {{ title }}
      <v-spacer />
      <slot name="title" />
    </v-card-title>

    <v-card-text>
      <template v-for="field in fields">
        <div v-if="field.component === 'header'" :key="field.id" class="mb-3">
          <div class="headline font-weight-light mt-3">{{ field.label }}</div>
          <div v-if="field.hint" class="subtitle-1">{{ field.hint }}</div>
          <v-divider />
        </div>

        <FieldWrap v-else v-bind="field" :key="field.id">
          <v-skeleton-loader v-if="loading" type="text" tile />
          <component
            v-else
            :is="field.component || 'string'"
            :value="getFieldValue(data, field)"
            :data="data"
            v-bind="field"
          />
        </FieldWrap>
      </template>
    </v-card-text>

    <slot name="footer" />
  </v-card>
</template>
