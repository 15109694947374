export default {
  url: "partners/phones",
  title: "Телефоны партнера",
  singularTitle: "Телефон партнера",
  static: true,
  permissions: {
    viewAny: false,
    create: true,
    update: true,
    delete: true,
  },

  fields: [
    { id: "id", label: "ID", component: "primary" },

    {
      id: "partner_id",
      label: "Партнер",
      sortable: false,
      component: "belongs-to",
      resourceName: "partners",
      relation: "partner",
    },

    { id: "value", label: "Телефон", sortable: false },
  ],
};
