import { predefinedPeriods } from "./enums";
import rules from "../core/rules";

function periodSelected() {
  return !!this.predefined_period;
}

export default {
  title: "Партнеры",
  singularTitle: "Партнер",
  name: "name",
  icon: "mdi-account-tie",
  serverOptions: true,
  permissions: {
    update: true,
    create: true,
  },

  fields: [
    { label: "ID", id: "id", component: "primary" },
    { label: "Имя", id: "name", max: 255, rules: [rules.required] },
    { label: "Город", id: "city", sortable: false, max: 255 },
    { label: "Внешний ID", id: "external_id", sortable: false, max: 255 },
    { label: "Баланс", id: "balance", disable: true },
    { id: "coupons_count", label: "Кол-во участников", disable: true },

    {
      label: "Создан",
      id: "created_at",
      component: "date",
      disable: true,
    },
  ],

  views: {
    report: {
      title: "Отчет по партнерам",
      url: "partners/report",
      fields: [
        { label: "ID", id: "id", component: "primary" },
        { label: "Внешний ID", id: "external_id", sortable: false },
        { label: "Имя", id: "name" },
        { label: "Город", id: "city", sortable: false },
        { label: "Клиентов", id: "new_clients", sortable: false },
        { label: "Кэшбэк", id: "cash_back_sum", sortable: false },
        { label: "Зачислений", id: "credit_sum", sortable: false },
        { label: "Списаний", id: "debit_sum", sortable: false },
      ],

      filters: [
        {
          id: "predefined_period",
          label: "Период",
          component: "enum",
          options: predefinedPeriods,
          clearable: true,
          default: "week",
        },

        {
          id: "from",
          label: "Дата от",
          component: "date",
          disable: periodSelected,
        },

        {
          id: "to",
          label: "Дата до",
          component: "date",
          endOfDay: true,
          disable: periodSelected,
        },
      ],
    },
  },

  relations: [
    {
      id: "phones",
      resourceName: "partnerPhones",
      foreignKey: "partner_id",
      relation: "phones",
    },

    {
      id: "couponSeries",
      resourceName: "couponSeries",
      foreignKey: "partner_id",
      relation: "couponSeries",
    },

    {
      id: "transactions",
      resourceName: "transactions",
      foreignKey: "target_id",
      morphKeyName: "target_type",
      morphKey: "partner",
      relation: "transactions",
    },
  ],
};
